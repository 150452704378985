import { useState } from 'react';
import { NavLink } from 'react-router-dom';

import {
  Stack,
  Divider,
  ListItemText,
  Button,
  Popper,
  Paper,
  List,
  ListItemButton,
  Typography,
  Box,
} from '@mui/material';
import { MenuOutlined, ChevronRight, ExpandMore } from '@mui/icons-material';

import { useBreakpoints } from '@vklink/components-shared';
import { observer } from '@vklink/libs-state';
import { useI18n } from '@vklink/libs-i18n';

import { useRootStore } from 'stores';
import { HomeRoutes, StaticRoutes } from '../routers';

type Item = {
  label: string;
  value: string;
  parent?: string;
};

type TreeItem = Item & {
  children: TreeItem[];
};

const NavigationBar = () => {
  const { commonStore } = useRootStore();
  const breakpoints = useBreakpoints();
  const { t } = useI18n(['components']);
  const [anchorPopoverEl, setAnchorPopoverEl] = useState<HTMLButtonElement | null>(null);

  const togglePopover = (event?: any) => {
    setAnchorPopoverEl((old) => {
      return !old && event.currentTarget ? event.currentTarget : null;
    });
  };

  const closePopover = () => {
    setAnchorPopoverEl(null);
  };

  const buildCategorySearchUrl = (cateId: string) => {
    return `${HomeRoutes.SEARCH}?categoryIds[]=${cateId}`;
  };

  const renderList = (
    list: TreeItem[],
    config?: {
      notForCategory?: boolean;
      anchorEl?: HTMLElement | null;
      childlessOnClick?: () => void;
    }
  ) => {
    return list.map((item) => {
      const haveChildren = !!item.children?.length;

      return (
        <SubPopoverItem
          key={item.value}
          label={item.label}
          href={config?.notForCategory ? item.value : buildCategorySearchUrl(item.value)}
          onClick={haveChildren ? undefined : config?.childlessOnClick}
          onRenderSub={
            haveChildren
              ? () => {
                  return (
                    <List
                      disablePadding
                      sx={{ minWidth: config?.anchorEl?.getBoundingClientRect().width || 0 }}
                    >
                      {renderList(item.children)}
                    </List>
                  );
                }
              : undefined
          }
        />
      );
    });
  };

  return (
    <>
      {breakpoints.md && (
        <Stack direction="row" justifyContent="flex-start" alignItems="center" maxHeight={48}>
          <Button
            onClick={togglePopover}
            startIcon={<MenuOutlined />}
            sx={{
              width: 240,
              p: 1.5,
              borderRadius: 0,
              textTransform: 'uppercase',
              fontWeight: 'bold',
              color: (theme) => theme.palette.common.white,
              backgroundColor: (theme) => theme.palette.primary.main,
              ':hover': {
                // color: (theme) => theme.palette.primary.main,
                backgroundColor: (theme) => theme.palette.secondary.main,
              },
            }}
          >
            {t('components:sideBar.allCategories')}
          </Button>
          {breakpoints.md && (
            <>
              <Divider flexItem orientation="vertical" variant="middle" />
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                mx={2}
              >
                {/* <NavigationItem label={t('components:sideBar.home')} href={HomeRoutes.ROOT} /> */}
                {/* <NavigationItem
                  label={t('components:sideBar.aboutUs')}
                  onRenderSub={({ anchorEl, closeSubPopover }) => {
                    return (
                      <List disablePadding>
                        {renderList(
                          SubCustomerCareList.map((item) => ({
                            label: t(item.title as any),
                            value: item.href || HomeRoutes.ROOT,
                            children: [],
                          })),
                          {
                            notForCategory: true,
                            anchorEl,
                            childlessOnClick: closeSubPopover,
                          }
                        )}
                      </List>
                    );
                  }}
                /> */}
                <NavigationItem
                  label={t('components:sideBar.aboutUs')}
                  href={HomeRoutes.ABOUT_US}
                />
                {/* <NavigationItem
                  label={t('components:sideBar.service')}
                  href={StaticRoutes.SERVICES}
                /> */}
                <NavigationItem
                  label={t('components:sideBar.businessAreas')}
                  href={StaticRoutes.BUSINESS_AREAS}
                />
                <NavigationItem
                  label={t('components:sideBar.shoppingCenter')}
                  href={HomeRoutes.SHOPPING_CENTER}
                  onRenderSub={({ anchorEl, closeSubPopover }) => {
                    return (
                      <List disablePadding>
                        {renderList(
                          [
                            { title: 'components:sideBar.products', href: HomeRoutes.SEARCH },
                            { title: 'components:sideBar.brands', href: HomeRoutes.SEARCH },
                          ].map((item) => ({
                            label: t(item.title as any),
                            value: item.href || HomeRoutes.ROOT,
                            children: [],
                          })),
                          {
                            notForCategory: true,
                            anchorEl,
                            childlessOnClick: closeSubPopover,
                          }
                        )}
                      </List>
                    );
                  }}
                />
                {/* <NavigationItem label={t('components:sideBar.products')} href={HomeRoutes.SEARCH} /> */}
                {/* <NavigationItem label={t('components:sideBar.brands')} href={HomeRoutes.BRANDS} /> */}
                <NavigationItem
                  label={t('components:sideBar.contactUs')}
                  href={HomeRoutes.CONTACT_US}
                />
              </Stack>
            </>
          )}
        </Stack>
      )}

      <Popper
        onMouseLeave={closePopover}
        open={Boolean(anchorPopoverEl)}
        anchorEl={anchorPopoverEl}
        placement="bottom-start"
        sx={{ zIndex: 1 }}
      >
        <Paper
          sx={{
            borderRadius: 0,
            backgroundColor: 'common.white',
            maxWidth: anchorPopoverEl?.getBoundingClientRect().width || 0,
            width: anchorPopoverEl?.getBoundingClientRect().width || 0,
            maxHeight: 300,
            overflowY: 'auto',
          }}
        >
          <List disablePadding>
            {renderList(commonStore.getHierarchyCategoryOptions, {
              anchorEl: anchorPopoverEl!,
              childlessOnClick: closePopover,
            })}
            <Divider />
          </List>
        </Paper>
      </Popper>
    </>
  );
};

export default observer(NavigationBar);

const NavigationItem = (props: {
  label: string;
  href?: string;
  onRenderSub?: (helper: {
    anchorEl: HTMLButtonElement;
    closeSubPopover: () => void;
  }) => React.ReactNode;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openSubPopover = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const closeSubPopover = () => {
    setAnchorEl(null);
  };

  const renderLink = (renderLabel: (isActive: boolean) => React.ReactNode) => {
    return (
      <Button sx={{ py: 1.5 }} onMouseOver={openSubPopover} onMouseLeave={closeSubPopover}>
        {props.href ? (
          <NavLink
            style={{
              textDecoration: 'none',
              backgroundColor: 'transparent',
            }}
            end
            to={props.href}
            className={({ isActive }) => (isActive ? 'active' : 'inactive')}
          >
            {({ isActive }) => renderLabel(isActive)}
          </NavLink>
        ) : (
          renderLabel(false)
        )}

        {!!props.onRenderSub && (
          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="bottom"
            onMouseLeave={closeSubPopover}
          >
            <Paper
              sx={{
                borderRadius: 0,
                backgroundColor: 'common.white',
              }}
            >
              {props.onRenderSub({
                anchorEl: anchorEl!,
                closeSubPopover,
              })}
            </Paper>
          </Popper>
        )}
      </Button>
    );
  };

  return (
    <>
      {renderLink((isActive) => {
        return (
          <Box
            component="div"
            sx={{
              display: 'flex',
              alignItems: 'center',
              borderRadius: 0,
              color: (theme) =>
                isActive ? theme.palette.warning.main : theme.palette.common.white,
              '& .MuiTypography-root': {
                fontWeight: 'bold',
                color: (theme) =>
                  isActive ? theme.palette.primary.main : theme.palette.secondary.main,
              },
              ':hover': {
                color: (theme) => theme.palette.primary.main,
                '& .MuiTypography-root': {
                  color: (theme) => theme.palette.primary.main,
                },
                '& .MuiSvgIcon-root': {
                  color: (theme) => theme.palette.primary.main,
                },
              },
            }}
          >
            <Typography>{props.label}</Typography>

            {!!props.onRenderSub && (
              <ExpandMore
                sx={{
                  color: 'secondary.main',
                }}
              />
            )}
          </Box>
        );
      })}
    </>
  );
};

const SubPopoverItem = (props: {
  label: string;
  href?: string;
  onClick?: () => void;
  onRenderSub?: () => React.ReactNode;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openSubPopover = (event: any) => {
    setAnchorEl(event?.currentTarget);
  };

  const closeSubPopover = () => {
    setAnchorEl(null);
  };

  const content = (
    <ListItemButton
      href={props.href as any}
      LinkComponent={!!props.href ? NavLink : undefined}
      onClick={props.onClick ?? openSubPopover}
      onMouseOver={openSubPopover}
      onMouseLeave={closeSubPopover}
    >
      <ListItemText primary={props.label} />
      {!!props.onRenderSub && (
        <>
          <ChevronRight />

          <Popper
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            placement="right-start"
            onMouseLeave={closeSubPopover}
            sx={{ zIndex: 1 }}
          >
            <Paper
              sx={{
                borderRadius: 0,
                backgroundColor: 'common.white',
              }}
            >
              {props.onRenderSub()}
            </Paper>
          </Popper>
        </>
      )}
    </ListItemButton>
  );

  return (
    <>
      {props.href ? (
        <NavLink
          style={{
            color: 'unset',
            textDecoration: 'none',
            backgroundColor: 'transparent',
          }}
          to={props.href}
        >
          {content}
        </NavLink>
      ) : (
        content
      )}
    </>
  );
};
