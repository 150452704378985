import { Box, Button, Dialog, Typography, Stack, Divider } from '@mui/material';
import { useBreakpoints, useDisclosure } from '@vklink/components-shared';
import { PriorityHigh } from '@mui/icons-material';
import StickyDialogHeader from '../StickyDialogHeader';
import CopyButton from '../CopyButton';
import { UploadInput } from 'pages/shared/models';
import { useForm, UploadField } from '@vklink/components-form';
import { BankInformation } from 'constant';
import { useI18n } from '@vklink/libs-i18n';

type Props = {
  onClick: (medias: UploadInput[]) => void;
  totalAmount: number | string;
  orderNumber: string;
  isDefaultOpen?: boolean;
};

type MediaModel = {
  medias: UploadInput[];
};

const BankTransferButton = (props: Props) => {
  const { isOpen, toggle } = useDisclosure(props.isDefaultOpen);
  const breakpoints = useBreakpoints();
  const { control, handleSubmit } = useForm<MediaModel>({
    mode: 'all',
  });
  const { t } = useI18n(['common']);

  const handleTransferBank = (data: MediaModel) => {
    toggle();
    props.onClick(data.medias);
  };

  const renderValue = (title: string, value: string) => (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography variant={breakpoints.md ? 'body1' : 'body2'} component="div" color="gray">
        {title}:
      </Typography>
      <Typography variant={breakpoints.md ? 'body1' : 'body2'} component="div">
        {value}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          textAlign: 'right',
        }}
      >
        <CopyButton text={value} />
      </Box>
    </Stack>
  );

  const renderAmountValue = (title: string, value: string | number) => (
    <Stack direction="row" alignItems="center" gap={1}>
      <Typography
        variant="body1"
        component="div"
        sx={{
          textDecoration: 'underline',
        }}
      >
        {title}:
      </Typography>
      <Typography
        variant="body1"
        component="div"
        color="primary"
        sx={{
          textDecoration: 'underline',
        }}
      >
        ₫{value}
      </Typography>
      <Box
        sx={{
          flexGrow: 1,
          textAlign: 'right',
        }}
      >
        <CopyButton text={value} />
      </Box>
    </Stack>
  );

  return (
    <>
      <Button
        variant="contained"
        onClick={toggle}
        sx={{
          backgroundColor: 'primary.main',

          ':hover': {
            backgroundColor: 'primary.main',
          },
        }}
      >
        {t('common:actions.bankTransfer')}
      </Button>

      <Dialog
        open={isOpen}
        onClose={toggle}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: 570,
          },
        }}
        fullScreen={!breakpoints.md}
      >
        <Box sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
          {breakpoints.md ? (
            <Typography variant="h6" component="div" color="secondary.main" px={2} pt={2}>
              {t('common:paymentMethods.paymentDue')}
            </Typography>
          ) : (
            <StickyDialogHeader
              title={
                <Typography variant="h6" component="div">
                  {t('common:paymentMethods.paymentDue')}
                </Typography>
              }
              onBack={toggle}
            />
          )}
          <Box p={2}>
            <Box p={1} border={1} borderColor="primary.main" borderRadius={2}>
              <Box display="flex" alignItems="center" gap={1}>
                <Box
                  sx={{
                    border: '1px solid',
                    height: 20,
                    width: 22,
                    borderRadius: '50%',
                    borderColor: 'primary.main',
                  }}
                >
                  <PriorityHigh fontSize="small" color="primary" sx={{ fontSize: 16 }} />
                </Box>
                <Typography variant="body1" component="div">
                  {t('common:paymentMethods.ruleBankTransfer')}
                </Typography>
              </Box>
            </Box>

            <Stack
              direction="column"
              p={2}
              spacing={2}
              divider={
                <Divider
                  flexItem
                  sx={{
                    my: 2,
                    backgroundColor: 'grey.300',
                  }}
                />
              }
            >
              {renderValue(t('common:paymentMethods.payTo'), BankInformation.nameOfUser)}
              {renderValue(t('common:paymentMethods.bank'), BankInformation.bankName)}
              {renderValue(t('common:paymentMethods.account'), BankInformation.bankNumber)}
              {renderValue(t('common:paymentMethods.addCommentBox'), props.orderNumber)}
              {renderAmountValue(t('common:paymentMethods.pleaseTransfer'), props.totalAmount)}
              <UploadField
                control={control}
                name="medias"
                multiple
                label={t('common:paymentMethods.uploadBankTransferReceipt')}
                uploadText={t('common:actions.clickOrDropHere')}
                removeText={t('common:actions.removeAllImages')}
              />
            </Stack>

            <Box display="flex" gap={2} justifyContent="flex-end">
              <Button
                type="reset"
                variant="outlined"
                sx={{
                  px: 4,
                  color: 'grey.400',
                  borderColor: 'grey.400',
                  textTransform: 'uppercase',
                }}
                onClick={toggle}
              >
                {t('common:actions.back')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleSubmit(handleTransferBank)}
                sx={{ px: 4, textTransform: 'uppercase' }}
              >
                {t('common:actions.send')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default BankTransferButton;
