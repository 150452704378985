export const STORE_INFO = {
  address: '135 Đường Số 5, Phường An Phú, TP.Thủ Đức, TP.Hồ Chí Minh',
  googleMapUrl: 'https://maps.app.goo.gl/jSH6hdEggUccg4UD6',
};

export const CONTACT_INFO = {
  email: 'kwon@vgsshop.com',
  emailHref: 'mailto:kwon@vgsshop.com',
  phoneNumber: '0394553256',
  phoneNumberHref: 'tel:+84394553256',
};
