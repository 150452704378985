import { Box, Stack, StackProps, Typography } from '@mui/material';
import { formatCurrency } from 'pages/shared/utils';
import ButtonAdjustment, { ButtonAdjustmentProps } from '../ButtonAdjustment';
import RatingButton, { AddOrderItemFeedbackProps } from '../OrderingButton/RatingButton';
import ProductAvatar from './ProductAvatar';
import ProductName from './ProductName';

type Props = {
  sx?: StackProps['sx'];
  product: {
    id: string;
    name: string;
    imgUrl: string | undefined;
    slug: string;
  };

  readonly?: boolean;

  attributeString: string;

  price: string | number;

  quantity: number;

  quantityAdjustmentProps?: Omit<ButtonAdjustmentProps, 'quantity'>;

  ratingProps?: AddOrderItemFeedbackProps;
};

const MobileOrderProductCard = (props: Props) => {
  const ProductQuantityBlock = (
    <>
      {props.readonly ? (
        <Typography variant="body2" component="p" color="grey.600">
          x {props.quantity}
        </Typography>
      ) : (
        <Box
          sx={{
            maxWidth: 120,
            maxHeight: {
              xs: 36,
              md: 'unset',
            },
          }}
        >
          {!!props.quantityAdjustmentProps && (
            <ButtonAdjustment quantity={props.quantity} {...props.quantityAdjustmentProps} />
          )}
        </Box>
      )}
    </>
  );

  return (
    <Stack
      direction="row"
      alignItems="center"
      sx={{
        flex: 1,
        overflowX: 'hidden',
        ...props.sx,
      }}
    >
      <Box
        sx={{
          minWidth: 70,
          maxWidth: 70,
          height: 70,
        }}
        border={1}
        borderColor="grey.400"
        p={1}
        borderRadius={2}
      >
        <ProductAvatar
          product={{
            id: props.product.id,
            imageUrl: props.product.imgUrl || '',
            slug: props.product.slug,
          }}
        />
      </Box>
      <Stack
        sx={{
          ml: 1,
          flex: 1,
          overflow: 'hidden',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            flex: 1,
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              flex: 1,
              overflow: 'hidden',
            }}
          >
            <ProductName
              name={props.product.name}
              id={props.product.id}
              slug={props.product.slug}
            />
          </Box>
          {!!props.ratingProps && <RatingButton {...props.ratingProps} />}
        </Stack>
        <Typography
          variant="body2"
          color="grey.600"
          sx={{
            minHeight: 20,
          }}
        >
          {props.attributeString}
        </Typography>

        <Stack
          direction="row"
          sx={{
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'wrap',
            rowGap: 0.5,
          }}
        >
          {ProductQuantityBlock}

          <Typography
            variant="body1"
            component="p"
            sx={{ fontWeight: 'bold', color: 'primary.main', ml: 'auto' }}
          >
            ₫{formatCurrency(props.price, 0, 3)}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MobileOrderProductCard;
