import { generatePath } from 'react-router';
import { Link } from 'react-router-dom';

import { Box, Grid, Typography, Chip, Stack } from '@mui/material';
import { MyProfileRoutes, ProductRoutes } from 'pages/shared/routers';
import { useBreakpoints } from '@vklink/components-shared';
import { Order, OrderItem, OrderItemAttributeValues } from 'pages/profile/store/models';
import { formatCurrency, getColorByStatus } from 'pages/shared/utils';
import { observer } from '@vklink/libs-state';
import { OrderStatusOptions } from 'constant';
import { useI18n } from '@vklink/libs-i18n';
import { dayjs, FormatDate } from 'enums';

const DetailOrder = (props: Order) => {
  const breakpoints = useBreakpoints();
  const { t } = useI18n(['pages', 'common']);

  const renderAttribute = (attributes: OrderItemAttributeValues[]) => {
    return attributes.map((item) => {
      return item.attributeValue;
    });
  };

  const renderProduct = (product: OrderItem) => (
    <Grid key={product.id} container justifyContent="space-between" my={2}>
      <Grid item display="flex" md={6} xs={12}>
        <Box
          component={'img'}
          src={product.productImageUrl ?? ''}
          alt={''}
          sx={{
            width: 70,
            height: 70,
          }}
          border={1}
          borderColor="grey.400"
          p={1}
          borderRadius={2}
          alignSelf="center"
        />
        <Box ml={1}>
          <Box
            component={Link}
            to={generatePath(ProductRoutes.ROOT, {
              id: product.productId,
              slug: product.productSlug,
            })}
            sx={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            {product.productName}
          </Box>
          <Typography variant="body2" component="div" color="grey.400">
            x {product.quantity}
          </Typography>
          <Typography variant="body2" component="div" color="grey.400">
            {renderAttribute(product.orderItemAttributeValues).toString()}
          </Typography>
          <Typography variant="body2" color="grey.600"></Typography>
          {!breakpoints.md && (
            <Box display="flex" gap={1}>
              <Typography
                variant="body2"
                component="div"
                color="grey.400"
                sx={{ textDecoration: 'line-through' }}
              >
                ₫{product.regularPrice ? formatCurrency(product.regularPrice, 0, 3) : '200,000'}
              </Typography>
              <Typography variant="body2" component="div" color="primary.main">
                ₫{product.sellPrice ? formatCurrency(product.sellPrice, 0, 3) : '150,000'}
              </Typography>
            </Box>
          )}
        </Box>
      </Grid>
      {breakpoints.md && (
        <Grid item display="flex" gap={1}>
          <Typography
            variant="body2"
            component="div"
            color="grey.400"
            sx={{ textDecoration: 'line-through' }}
          >
            ₫{product.regularPrice ? formatCurrency(product.regularPrice, 0, 3) : '200,000'}
          </Typography>
          <Typography variant="body2" component="div" color="primary.main">
            ₫{product.sellPrice ? formatCurrency(product.sellPrice, 0, 3) : '150,000'}
          </Typography>
        </Grid>
      )}
    </Grid>
  );

  const renderStatus = () => {
    const { status } = props;

    return (
      <Chip
        label={t(OrderStatusOptions.find((item) => item.value === status)?.label as any)}
        sx={{ backgroundColor: getColorByStatus(status), color: 'common.white' }}
      />
    );
  };

  return (
    <Stack
      sx={{
        backgroundColor: 'common.white',
        py: {
          xs: 2,
          md: 1,
        },
        px: {
          xs: 1,
          md: 2,
        },
        // boxShadow: 1,
        // borderBottom: (theme) => `1px solid ${theme.palette.grey[300]}`,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        // sx={{
        //   borderBottom: (theme) => `1px dotted ${theme.palette.grey[800]}`,
        // }}
      >
        <Stack
          direction="row"
          sx={{
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Typography
            variant="body2"
            component="p"
            sx={{
              color: 'grey.600',
              mr: 1,
            }}
          >
            {t('pages:orders.orderNumber')}
          </Typography>
          <Typography
            variant="body1"
            component={Link}
            to={generatePath(MyProfileRoutes.PURCHASE_ORDER_DETAIL, {
              id: props.id,
            })}
            sx={{
              color: 'primary.main',
              textDecoration: 'none',
            }}
          >
            {props.orderNumber}
          </Typography>
        </Stack>
        {renderStatus()}
      </Stack>

      {/* Show first 2 elements in array */}
      {props.orderItems.slice(0, 2).map((item) => renderProduct(item))}

      <Grid
        container
        sx={{
          pt: 1,
          borderTop: (theme) => `1px dotted ${theme.palette.grey[300]}`,
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: breakpoints.md ? 'flex-start' : 'flex-end',
          }}
        >
          <Typography
            component="p"
            sx={{ color: 'grey.600', fontSize: (theme) => theme.typography.fontSize }}
          >
            {t('pages:orders.orderDate')}:
          </Typography>
          <Typography
            component="p"
            sx={{
              ml: 1,
              color: 'grey.600',
              fontSize: (theme) => theme.typography.fontSize,
              minWidth: 120,
              textAlign: breakpoints.md ? 'left' : 'right',
            }}
          >
            {dayjs(props.sellDate).format(FormatDate.DATE)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction="row"
            sx={{
              alignItems: 'center',
              justifyContent: 'flex-end',
              // borderStyle: 'dotted'
            }}
          >
            <Typography
              component="p"
              sx={{ color: 'grey.600', fontSize: (theme) => theme.typography.fontSize }}
            >
              {t('pages:orders.totalAmount')}:
            </Typography>
            <Typography
              variant="h6"
              component="p"
              sx={{
                color: 'primary.main',
                minWidth: 120,
                ml: 1,
                textAlign: 'right',
              }}
            >
              ₫{formatCurrency(props.totalPrice, 0, 3)}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default observer(DetailOrder);
