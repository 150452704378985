import { Box, Button, Typography } from '@mui/material';
import NotFound from 'assets/Not_found.png';

type Props = {
  isShopping?: boolean;
  content: string;
};

const EmptyCart = (props: Props) => {
  return (
    <Box textAlign="center" sx={{ backgroundColor: 'common.white' }}>
      <Box component="img" src={NotFound} sx={{ maxWidth: 300 }} alt="Not Found" />
      <Typography variant="inherit" component="div" py={2}>
        {props.content}
      </Typography>
      {props.isShopping && (
        <Button
          variant="contained"
          color="primary"
          sx={{
            py: 1,
            px: 3,
            borderRadius: 0,
            // border: (theme) => `1px solid ${theme.palette.warning.main}`,
            // backgroundColor: 'warning.main',
            fontWeight: 'bold',
            color: 'common.white',

            // ':hover': {
            //   backgroundColor: 'common.white',
            //   color: 'common.black',
            // },
          }}
          href="/"
        >
          Shopping Now
        </Button>
      )}
    </Box>
  );
};

export default EmptyCart;
