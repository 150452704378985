import { useState } from 'react';

import { DeleteOutlined } from '@mui/icons-material';
import {
  Grid,
  Box,
  Typography,
  IconButton,
  Checkbox,
  FormControlLabel,
  Stack,
} from '@mui/material';

import { useBreakpoints } from '@vklink/components-shared';
import { observer } from '@vklink/libs-state';

import ButtonAdjustment from './ButtonAdjustment';
import { useRootStore } from 'stores';
import { CartItemInstance } from 'stores/models';
import { formatCurrency } from '../utils';
import ProductName from './ProductCards/ProductName';
import ProductAvatar from './ProductCards/ProductAvatar';
import MobileOrderProductCard from './ProductCards/MobileOrderProductCard';
import GiftDetail from './OrderingProcess/GiftDetail';

type Props = {
  selection?: {
    isSelected: boolean;
    onSelect?: (checked: boolean) => void;
  };
  cartItem: CartItemInstance;
  readonly?: boolean;
  index?: number;
};

const OrderProductItem = (props: Props) => {
  const breakpoints = useBreakpoints();
  const { commonStore } = useRootStore();
  const [quantity, setQuantity] = useState(props.cartItem.quantity);

  const arrayProduct = () => {
    if (props.readonly) {
      return commonStore.getSelectedItems;
    } else {
      return commonStore.getProductInCart;
    }
  };

  const checkShowGiftPosition = () => {
    let checkCondition = false;

    const getProductInCart = arrayProduct() || [];

    const listProductId = commonStore.getSelectedItems?.map((el) => el.productId) || [];

    if (!listProductId.includes(props.cartItem.productId)) return false;
    if (props.cartItem.promotionDetails.length === 0) return false;

    // Reverse array to get index of last item
    for (let i = getProductInCart?.length - 1; i >= 0; i--) {
      if (getProductInCart[i].productId === props.cartItem.productId) {
        if (i === props.index) {
          checkCondition = true;
        }
        break;
      }
    }

    return checkCondition;
  };

  const handleIncreaseProduct = () => {
    commonStore.updateQuantityProduct(props.cartItem.id.toString(), props.cartItem.quantity + 1, {
      success: async () => {
        await commonStore.getCartItemsAsync();
        if (!props.readonly) {
          setQuantity(props.cartItem.quantity);
        }
      },
    });
  };

  const handleDecreaseProduct = () => {
    commonStore.updateQuantityProduct(props.cartItem.id.toString(), props.cartItem.quantity - 1, {
      success: async () => {
        await commonStore.getCartItemsAsync();
        if (!props.readonly) {
          setQuantity(props.cartItem.quantity);
        }
      },
    });
  };

  const handleDeleteProduct = () => {
    commonStore.deleteProductInCart([props.cartItem.id.toString()], {
      success: () => {
        commonStore.getCartItemsAsync();
      },
    });
  };

  const handleChangeQuantity = (quantityProduct: number) => {
    commonStore.updateQuantityProduct(props.cartItem.id.toString(), quantityProduct, {
      success: () => {
        commonStore.getCartItemsAsync();
      },
    });
  };

  const ProductQuantityBlock = (
    <>
      {props.readonly ? (
        <Typography variant="body2" component="p" color="grey.400">
          {props.cartItem.quantity}
        </Typography>
      ) : (
        <Box
          sx={{
            maxWidth: 120,
            maxHeight: {
              xs: 35,
              md: 'unset',
            },
          }}
        >
          <ButtonAdjustment
            quantity={quantity}
            decrease={handleDecreaseProduct}
            increase={handleIncreaseProduct}
            onChange={setQuantity}
            onBlur={handleChangeQuantity}
          />
        </Box>
      )}
    </>
  );

  const ProductCheckboxBlock = (
    <>
      {props.selection?.onSelect && (
        <FormControlLabel
          sx={{
            marginRight: 0,
            pt: breakpoints.md ? 0 : 2,
          }}
          label=""
          control={
            <Checkbox
              checked={props.selection?.isSelected}
              onChange={(_, checked) =>
                props.selection?.onSelect && props.selection?.onSelect(checked)
              }
              size="small"
              sx={{
                width: 40,
                '& .Mui-checked': {
                  color: 'primary.main',
                },
              }}
            />
          }
        />
      )}
    </>
  );

  const ProductImgBlock = (
    <Box
      sx={{
        width: 70,
        height: 70,
      }}
      border={1}
      borderColor="grey.400"
      p={1}
      borderRadius={2}
    >
      <ProductAvatar
        product={{
          id: props.cartItem.productId,
          imageUrl: props.cartItem.productImageUrl || '',
          slug: props.cartItem.productSlug,
        }}
      />
    </Box>
  );

  const ProductInfoBlock = (
    <Stack direction="column" sx={{ overflow: 'hidden' }}>
      <ProductName
        name={props.cartItem.productName}
        id={props.cartItem.productId}
        slug={props.cartItem.productSlug}
      />
      <Typography variant="body2" component="div" color="grey.400">
        {props.cartItem.getAttributeValues.toString()}
      </Typography>
    </Stack>
  );

  return (
    <Stack
      className="order-product-item"
      sx={{
        py: 1,
      }}
    >
      {breakpoints.md ? (
        <Grid container justifyContent="space-between" my={2}>
          <Grid item gap={2} xs={5} sx={{ display: 'flex', alignItems: 'center' }}>
            <Stack direction="row">
              {ProductCheckboxBlock}
              {ProductImgBlock}
            </Stack>
            {ProductInfoBlock}
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            {ProductQuantityBlock}
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography variant="body2" component="p" sx={{ color: 'grey.800' }}>
              ₫{formatCurrency(props.cartItem.sellPrice, 0, 3)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Typography
              variant="body1"
              component="p"
              sx={{ fontWeight: 'bold', color: 'primary.main' }}
            >
              ₫{formatCurrency(props.cartItem.getTotalPrice, 0, 3)}
            </Typography>
          </Grid>
          {!props.readonly && (
            <Grid
              item
              xs={1}
              sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <IconButton size="small" color="error" onClick={handleDeleteProduct} sx={{ p: 0 }}>
                <DeleteOutlined />
              </IconButton>
            </Grid>
          )}
        </Grid>
      ) : (
        <Stack my={2} direction="row" alignItems="flex-start">
          {ProductCheckboxBlock}
          <MobileOrderProductCard
            product={{
              name: props.cartItem.productName,
              id: props.cartItem.productId,
              slug: props.cartItem.productSlug,
              imgUrl: props.cartItem.productImageUrl || '',
            }}
            attributeString={props.cartItem.getAttributeValues.join(', ')}
            price={props.cartItem.getTotalPrice}
            quantity={props.cartItem.quantity}
            readonly={props.readonly}
            quantityAdjustmentProps={{
              decrease: handleDecreaseProduct,
              increase: handleIncreaseProduct,
              onChange: setQuantity,
              onBlur: handleChangeQuantity,
            }}
          />
        </Stack>
      )}
      {checkShowGiftPosition() && (
        <GiftDetail
          giftDetail={props.cartItem.promotionDetails[0]?.giftDetails}
          readonly={props.readonly}
        />
      )}
    </Stack>
  );
};

export default observer(OrderProductItem);
