import { AppBar, Container, Divider } from '@mui/material';

import NavigationBar from './NavigationBar';
import ActionBar from './ActionBar';
import { useScrollDirection } from '@vklink/components-shared';

const AppHeader = () => {
  const scrollDirection = useScrollDirection();

  return (
    <AppBar
      position="sticky"
      sx={{
        boxShadow: 'none',
        // backgroundColor: 'primary.main',
        backgroundColor: 'common.white',
        top: scrollDirection === 'up' ? 0 : -250,
        transition: 'top 0.5s ease-in-out',
        marginBottom: 0,
      }}
    >
      <Container>
        <ActionBar />
        <Divider
          flexItem
          sx={{
            display: {
              xs: 'none',
              md: 'block',
            },
            backgroundColor: (theme) => theme.palette.divider,
          }}
        />
        <NavigationBar />
      </Container>
      <Divider
        sx={{
          height: 1,
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      />
    </AppBar>
  );
};

export default AppHeader;
