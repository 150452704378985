import { generatePath, useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import {
  Avatar,
  Box,
  Fade,
  IconButton,
  Stack,
  Typography,
  Link,
  Divider,
  Popper,
  Button,
} from '@mui/material';
import {
  CloseOutlined,
  PersonOutlineOutlined,
  SearchOutlined,
  ShoppingCartOutlined,
} from '@mui/icons-material';

import { TextField, useForm } from '@vklink/components-form';
import { useAnchorElDisclosure, useBreakpoints } from '@vklink/components-shared';
import { useAuthStore } from '@vklink/libs-auth';
import { observer } from '@vklink/libs-state';
import { useRootStore } from 'stores';
import {
  AuthenticationRoutes,
  CartRoutes,
  HomeRoutes,
  MyProfileRoutes,
  ProductRoutes,
} from '../routers';
import ActionBarButton, { BadgeIcon } from './ActionBarButton';
import { formatCurrency } from '../utils';
import ProductCardAvatar from './ProductCards/ProductAvatar';
import AppSidebarButton from './AppSidebarButton';

import avatar from 'assets/avatar-default.jpeg';
import logoVKLink from 'assets/logo-ecommerce.svg';
import { useI18n } from '@vklink/libs-i18n';
import { useEffect } from 'react';
import ChangeLanguage from './ChangeLanguage';
import { useToast } from '@vklink/components-toast-message';

const ActionBar = () => {
  const theme = useTheme();
  const navigator = useNavigate();
  const authStore = useAuthStore();
  const breakpoints = useBreakpoints();
  const { control, handleSubmit, setValue } = useForm<{ search: string }>({
    defaultValues: { search: '' },
  });
  const { t } = useI18n(['common']);

  useEffect(() => {
    if (window.location.pathname !== HomeRoutes.SEARCH) {
      setValue('search', '');
    }
  }, [window.location.pathname]);

  const onSearch = (dataSearch: { search: string }) => {
    navigator(HomeRoutes.SEARCH + `?keyword=${dataSearch.search}`);
  };

  const AppLogo = (
    <Box
      component="img"
      sx={{
        ml: breakpoints.md ? 0 : !authStore.isAuthenticated ? -5 : undefined,
        height: {
          xs: 40,
          md: 50,
        },
        cursor: 'pointer',
        objectFit: 'contain',
        alignSelf: 'flex-start',
      }}
      alt="app-logo"
      src={logoVKLink}
      onClick={() => navigator('/')}
    />
  );

  return (
    <Stack
      direction="row"
      // direction={{
      //   xs: 'column',
      //   sm: 'row',
      // }}
      // spacing={3}
      rowGap={1}
      justifyContent="space-between"
      alignItems="center"
      py={{
        xs: 1,
        md: 3,
      }}
    >
      {breakpoints.md && AppLogo}

      <Fade
        in={breakpoints.md}
        unmountOnExit
        style={{
          transitionDelay: breakpoints.md ? `${theme.transitions.duration.standard}ms` : '0ms',
        }}
      >
        <Stack
          component={'form'}
          onSubmit={handleSubmit(onSearch)}
          sx={{
            mx: 4,
            maxWidth: 550,
            flex: 1,
          }}
        >
          <TextField
            control={control}
            name="search"
            sx={{
              '& .MuiOutlinedInput-root': {
                flex: 1,
                pr: 0,
                backgroundColor: (theme) => theme.palette.common.white,
                borderRadius: 5,
                borderColor: (theme) => theme.palette.primary.main,

                // '&.MuiInputBase-root.Mui-focused fieldset': {
                //   borderColor: (theme) => theme.palette.primary.main,
                // },
                // '& fieldset': {
                //   borderColor: (theme) => theme.palette.primary.main,
                // },
                // '&:hover fieldset': {
                //   borderColor: (theme) => theme.palette.primary.main,
                // },
                // '&.Mui-focused fieldset': {
                //   borderColor: (theme) => theme.palette.primary.main,
                // },
              },
            }}
            autoComplete="off"
            placeholder={t('common:search.products')}
            endAdornment={
              <IconButton
                type="submit"
                sx={{
                  backgroundColor: theme.palette.common.white,
                  color: theme.palette.primary.main,
                  borderRadius: 0,
                  borderTopRightRadius: 'inherit',
                  borderBottomRightRadius: 'inherit',
                  ':hover': {
                    backgroundColor: theme.palette.common.white,
                  },
                }}
                onClick={handleSubmit(onSearch)}
              >
                <SearchOutlined />
              </IconButton>
            }
          />
        </Stack>
      </Fade>

      <Stack
        sx={{
          width: {
            xs: '100%',
            md: 'unset',
          },
        }}
        spacing={{
          sm: 1,
          md: 2,
        }}
        direction="row"
        alignItems="center"
      >
        {!breakpoints.md && <AppSidebarButton />}

        {breakpoints.md && <UserActionButton />}

        <Box
          sx={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {!breakpoints.md && AppLogo}
          {breakpoints.md && <ChangeLanguage />}
        </Box>
        {!breakpoints.md && <ChangeLanguage />}

        {authStore.isAuthenticated && <CartActionButton />}
      </Stack>
    </Stack>
  );
};

export default observer(ActionBar);

const UserActionButton = observer(() => {
  const breakpoints = useBreakpoints();
  const { commonStore } = useRootStore();
  const authStore = useAuthStore();
  const navigator = useNavigate();
  const { isOpen, open, close, anchorEl } = useAnchorElDisclosure();
  const toast = useToast();
  const { t } = useI18n(['common']);

  const handleLogout = () => {
    authStore.signoutDirectlyAsync().then(() => {
      toast(
        t('common:notification.success'),
        t('common:notification.logoutSuccessfully'),
        'success',
        'filled'
      );
    });
  };

  return (
    <ActionBarButton
      onMouseOver={(e) => {
        authStore.isAuthenticated && open(e);
      }}
      onMouseLeave={close}
      right={() => {
        return (
          <>
            {authStore.isAuthenticated ? (
              <IconButton
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: (theme) =>
                    !breakpoints.md ? theme.palette.secondary.main : undefined,
                }}
              >
                <Avatar alt="user-avatar" src={commonStore.userInformation?.avatar ?? avatar} />
              </IconButton>
            ) : (
              <PersonOutlineOutlined sx={{ fontSize: 30, color: 'secondary.main' }} />
            )}
          </>
        );
      }}
      leftLabel={
        authStore.isAuthenticated
          ? breakpoints.md
            ? t('common:user.myAccount')
            : undefined
          : t('common:user.account')
      }
      leftValue={
        authStore.isAuthenticated ? (
          breakpoints.md ? (
            (commonStore.userInformation?.username ?? commonStore.userInformation?.phone) ||
            'Username'
          ) : undefined
        ) : (
          <Stack direction="row" spacing={0.5}>
            <Link href={AuthenticationRoutes.LOGIN} underline="none">
              <Typography color="secondary.main">{t('common:actions.signIn')}</Typography>
            </Link>
            <Divider
              orientation="vertical"
              flexItem
              variant="middle"
              sx={{
                backgroundColor: (theme) => theme.palette.secondary.main,
              }}
            />
            <Link href={AuthenticationRoutes.SIGN_UP} underline="none">
              <Typography color="secondary.main">{t('common:actions.signUp')}</Typography>
            </Link>
          </Stack>
        )
      }
      onRenderSub={() => {
        return (
          <Popper
            open={isOpen}
            anchorEl={anchorEl}
            placement="bottom"
            onMouseLeave={close}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 1, boxShadow: 9 }}
          >
            <Divider sx={{ backgroundColor: 'secondary.main' }} />
            <Box
              p={2}
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                minWidth: {
                  xs: 200,
                  sm: 250,
                },
              }}
            >
              {/* <Box display="flex" columnGap={2}>
                <Avatar
                  alt="user-avatar"
                  src={commonStore.userInformation?.avatar ?? avatar}
                  sx={{ border: 1, borderColor: 'gray' }}
                />
                <Typography variant="inherit" component="div" fontWeight="bold" alignSelf="center">
                  {commonStore.userInformation?.username ?? commonStore.userInformation?.phone}
                </Typography>
              </Box> */}
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: 'common.black',
                  fontSize: (theme) => theme.typography.fontSize * 1.2,
                }}
              >
                {t('common:user.myAccount')}
              </Typography>
              <Divider sx={{ mt: 2, backgroundColor: 'grey.300' }} />
              <Stack
                spacing={1}
                sx={{
                  mt: 2,
                  '& .MuiTypography-root': {
                    cursor: 'pointer',
                    color: 'grey.600',

                    ':hover': {
                      color: 'primary.main',
                    },
                  },
                }}
              >
                <Typography
                  onClick={() => {
                    close();
                    navigator(MyProfileRoutes.EDIT_PROFILE);
                  }}
                >
                  {t('common:user.myAccount')}
                </Typography>
                <Typography
                  onClick={() => {
                    close();
                    navigator(MyProfileRoutes.PURCHASE_ORDER);
                  }}
                >
                  {t('common:user.myOrders')}
                </Typography>
                <Typography
                  onClick={() => {
                    close();
                    handleLogout();
                  }}
                >
                  {t('common:actions.logout')}
                </Typography>
              </Stack>
            </Box>
          </Popper>
        );
      }}
    />
  );
});

const CartActionButton = observer(() => {
  const breakpoints = useBreakpoints();
  const { isOpen, open, close, anchorEl } = useAnchorElDisclosure();
  const { commonStore } = useRootStore();
  const authStore = useAuthStore();
  const navigator = useNavigate();
  const { t } = useI18n(['common', 'pages']);

  const handleRemoveProduct = (id: string) => {
    commonStore.deleteProductInCart([id], {
      success: () => {
        commonStore.getCartItemsAsync();
      },
    });
  };

  return (
    <ActionBarButton
      onMouseOver={(e) => {
        if (!authStore.isAuthenticated) {
          return;
        }

        if (breakpoints.md) {
          open(e);
        } else {
          navigator(CartRoutes.ORDER_CART);
        }
      }}
      onMouseLeave={close}
      right={() => {
        return <BadgeIcon value={commonStore.getTotalProductInCart} icon={ShoppingCartOutlined} />;
      }}
      leftLabel={breakpoints.md ? t('common:user.cart') : undefined}
      leftValue={
        breakpoints.md ? `₫${formatCurrency(commonStore.getTotalPriceInCart, 0, 3)}` : undefined
      }
      onRenderSub={() => {
        return (
          <Popper
            open={isOpen}
            anchorEl={anchorEl}
            placement="bottom-end"
            onMouseLeave={close}
            sx={{ zIndex: (theme) => theme.zIndex.appBar + 1, boxShadow: 9 }}
          >
            <Divider sx={{ backgroundColor: 'primary.main' }} />
            <Box
              p={2}
              sx={{
                backgroundColor: (theme) => theme.palette.common.white,
                minWidth: {
                  xs: 300,
                  sm: 350,
                },
                maxWidth: 350,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 'bold',
                  color: 'common.black',
                  fontSize: (theme) => theme.typography.fontSize * 1.2,
                }}
              >
                {t('common:user.cart')}
                {!!commonStore.getTotalProductInCart && (
                  <Typography
                    component="span"
                    sx={{ ml: 1, fontSize: (theme) => theme.typography.fontSize - 2 }}
                  >
                    {t('common:user.quantityItemsInCart', {
                      quantity: commonStore.getTotalProductInCart,
                    })}
                  </Typography>
                )}
              </Typography>
              <Divider sx={{ mt: 2, backgroundColor: 'grey.300' }} />

              <Stack
                spacing={2}
                sx={{
                  my: 2,
                  maxHeight: 400,
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                {!!commonStore.getProductInCart?.length ? (
                  commonStore.getProductInCart.map((item) => (
                    <Stack key={item.id} direction="row" sx={{ position: 'relative' }}>
                      <Box
                        sx={{
                          minWidth: 70,
                          maxWidth: 70,
                        }}
                      >
                        <ProductCardAvatar
                          product={{
                            id: item.id,
                            imageUrl: item.productImageUrl,
                            slug: item.productSlug,
                          }}
                          hideDiscount
                        />
                      </Box>
                      <Stack sx={{ ml: 0.5, overflow: 'hidden' }}>
                        <NavLink
                          style={{
                            textDecoration: 'none',
                            backgroundColor: 'transparent',
                          }}
                          to={generatePath(ProductRoutes.ROOT, {
                            id: item.productId,
                            slug: item.productSlug,
                          })}
                        >
                          <Typography
                            component="h6"
                            sx={{
                              fontSize: (theme) => theme.typography.fontSize * 1.1,
                              fontWeight: 'bold',
                              color: 'common.black',
                              mb: 0.2,
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              ':hover': {
                                color: 'primary.main',
                              },
                            }}
                          >
                            {item.productName}
                          </Typography>
                        </NavLink>
                        <Typography component="span" color="grey.400">
                          <Typography
                            component="span"
                            sx={{
                              fontSize: (theme) => theme.typography.fontSize + 2,
                              color: 'primary.main',
                            }}
                          >
                            ₫{formatCurrency(item.sellPrice, 0, 3)}
                          </Typography>{' '}
                          x {formatCurrency(item.quantity, 0, 3)}
                        </Typography>
                        <Typography variant="body2" color="grey.600">
                          {item.getAttributeValues.join(', ')}
                        </Typography>
                      </Stack>

                      <IconButton
                        size="small"
                        onClick={() => handleRemoveProduct(item.id)}
                        sx={{
                          position: 'absolute',
                          right: 0,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          zIndex: 1,

                          ':hover': {
                            color: 'primary.main',
                          },
                        }}
                      >
                        <CloseOutlined fontSize="small" />
                      </IconButton>
                    </Stack>
                  ))
                ) : (
                  <Typography
                    sx={{
                      my: 3,
                      fontSize: (theme) => theme.typography.fontSize + 2,
                      color: 'common.black',
                      textAlign: 'center',
                    }}
                  >
                    {t('common:user.emptyYourCart')}
                  </Typography>
                )}
              </Stack>

              <Divider sx={{ mb: 3, backgroundColor: 'grey.300' }} />

              <Stack direction="row" justifyContent="space-between" mb={3}>
                <Typography
                  component="h6"
                  sx={{
                    fontWeight: 'bold',
                    color: 'common.black',
                    fontSize: (theme) => theme.typography.fontSize,
                  }}
                >
                  {t('pages:cart.subtotal')}
                </Typography>

                <Typography
                  component="span"
                  sx={{
                    fontWeight: 'bold',
                    fontSize: (theme) => theme.typography.fontSize - 2,
                    color: 'primary.main',
                  }}
                >
                  ₫{formatCurrency(commonStore.getTotalPriceInCart, 0, 3)}
                </Typography>
              </Stack>

              <Button
                href={CartRoutes.ORDER_CART}
                fullWidth
                variant="contained"
                color="primary"
                sx={{
                  borderRadius: 0,
                  fontWeight: 'bold',
                  color: 'common.white',
                  fontSize: (theme) => theme.typography.fontSize + 2,
                }}
                onClick={close}
              >
                {t('common:actions.viewCart')}
              </Button>
            </Box>
          </Popper>
        );
      }}
    />
  );
});
