import 'pure-react-carousel/dist/react-carousel.es.css';

import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel';
import { Box, BoxProps } from '@mui/material';
import { CircleOutlined } from '@mui/icons-material';

type CarouselProps = {
  slider: SlideItem[];
  sx?: BoxProps['sx'];
};

type SlideItem = {
  src: string;

  render?: () => React.ReactNode;

  imgRender?: () => React.ReactNode;
};

const Carousel = (props: CarouselProps) => {
  return (
    <Box sx={{ position: 'relative', ...props.sx }}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={props.slider.length}
        infinite
        isIntrinsicHeight
        isPlaying
      >
        <Slider>
          {props.slider.map((item, index) => {
            return (
              <Slide
                style={{
                  position: 'relative',
                }}
                index={index}
                key={index}
              >
                {!!item.imgRender ? (
                  item.imgRender()
                ) : (
                  <img
                    src={item.src}
                    alt="img"
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                    }}
                    loading="lazy"
                  />
                )}

                {!!item.render && item.render()}
              </Slide>
            );
          })}
        </Slider>
        <Box
          className="carousel-dots"
          textAlign="center"
          sx={{
            position: 'absolute',
            left: '50%',
            bottom: '1%',
            transform: 'translateX(-50%)',
            '& .carousel__dot': {
              backgroundColor: 'transparent',
              border: 0,
            },
            '& .carousel__dot--selected': {
              top: -2,
              position: 'relative',
            },
            '& .carousel__dot--selected .MuiSvgIcon-root': {
              backgroundColor: (theme) => theme.palette.primary.main,
              borderRadius: 25,
              height: 12,
              width: 12,
            },
          }}
        >
          {props.slider.map((slide, index) => (
            <Dot slide={index} key={index}>
              <CircleOutlined fontSize="small" color="primary" sx={{ fontSize: 16 }} />
            </Dot>
          ))}
        </Box>
      </CarouselProvider>
    </Box>
  );
};

export default Carousel;
