import commonBank from 'assets/common-bank.png';
import { Stack, Box, Typography, Button, IconButton } from '@mui/material';
import { useBreakpoints } from '@vklink/components-shared';
import { NavigateNextOutlined } from '@mui/icons-material';

const BankDetail = () => {
  const breakpoints = useBreakpoints();

  return (
    <Stack direction="row" spacing={2} pl={breakpoints.md ? 0 : 2}>
      <Box
        alignSelf={breakpoints.md ? 'flex-start' : 'center'}
        component="img"
        src={commonBank}
        alt={'bank'}
        sx={{ width: 70, height: 70 }}
      />
      <Stack flex={1}>
        <Typography variant="body1" component="div" color="gray">
          Vietnam International Commercial Joint Stock Bank
        </Typography>
        <Typography variant="body1" component="div" color="gray">
          Full name: Trang Hong Tuan
        </Typography>
        <Typography variant="body2" component="div" color="gray">
          Area: Ho Chi Minh City Branch: District 5
        </Typography>
      </Stack>
      {breakpoints.md ? (
        <>
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body1" component="div" color="primary.main">
                Approved
              </Typography>
              <Button variant="contained" color="primary" size="small" sx={{ ml: 2 }}>
                Default
              </Button>
            </Stack>
            <Typography variant="body1" component="div" color="black" flexGrow={1}>
              *9017
            </Typography>
          </Stack>
          <Stack alignItems={'flex-end'}>
            <Button variant="text" disabled>
              Edit
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: 'grey.400',
                borderColor: 'grey.400',
              }}
            >
              Default settings
            </Button>
          </Stack>
        </>
      ) : (
        <IconButton>
          <NavigateNextOutlined />
        </IconButton>
      )}
    </Stack>
  );
};

export default BankDetail;
