import { Typography, TypographyProps } from '@mui/material';

type Props = {
  discountPercent: number;

  sx?: TypographyProps['sx'];
};

const DiscountTag = (props: Props) => {
  return (
    <Typography
      position="absolute"
      color="white"
      variant="body2"
      sx={{
        top: 30,
        left: 10,
        px: 1,
        backgroundColor: (theme) => theme.palette.primary.light,
        borderRadius: 0.5,
        zIndex: 1,

        ...props.sx,
      }}
    >
      -{props.discountPercent}%
    </Typography>
  );
};

export default DiscountTag;
