import { OrderStatus, RequestType } from 'enums';

export const getColorByStatus = (status: string) => {
  switch (status) {
    case OrderStatus.WAITING:
      return '#2E4576';
    case OrderStatus.CONFIRMED:
      return '#B196DB';
    case OrderStatus.PACKED:
      return '#0288D1';
    case OrderStatus.SHIPPING:
      return '#FFBD33';
    case OrderStatus.DELIVERED:
      return '#ED6C02';
    case OrderStatus.RECEIVED:
      return '#0AB39C';
    case OrderStatus.CANCEL:
      return '#D32F2F';
    default:
      return '#fff';
  }
};

export const isVietnamesePhoneNumber = /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/;

export const isEmailAddress =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const isUsername = /^(?:[A-Z\d][A-Z\d_-]{5,10}|[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4})$/i;

export const isPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;

export const getKeyByValue = (object: any, value: any) => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const replaceNewLineWithBr = (text: string) => {
  return text.replace(/\\n/g, '<br />');
};

export const findRequestType = (username: string) => {
  if (isEmailAddress.test(username)) {
    return RequestType.EMAIL;
  }
  // if (isVietnamesePhoneNumber.test(username)) {
  //   return RequestType.PHONENUMBER;
  // }
  return null;
};

export const strip = (number: number) => {
  return parseFloat(number.toPrecision(12));
};
