import { generatePath, Link } from 'react-router-dom';

import { Grid, Box, Typography, IconButton, Avatar } from '@mui/material';
import { ChevronRightOutlined } from '@mui/icons-material';
import { UserPoint } from 'pages/profile/store/models';
import { useI18n } from '@vklink/libs-i18n';
import { dayjs, FormatDate, HistoryStatus } from 'enums';
import { formatCurrency } from 'pages/shared/utils';
import { MyProfileRoutes } from 'pages/shared/routers';
import { HistoryStatusOptions } from 'constant';
import { useBreakpoints } from '@vklink/components-shared';
import { observer } from '@vklink/libs-state';
import promotion from 'assets/promotion-3.png';

const DetailPoint = (props: UserPoint) => {
  const { t } = useI18n(['pages']);
  const breakpoints = useBreakpoints();

  const isReceivePoint = () => {
    return (
      props.historyStatus === HistoryStatus.GIFT || props.historyStatus === HistoryStatus.CANCEL
    );
  };

  return (
    <Grid container justifyContent="space-between" my={2} columnSpacing={2}>
      <Grid item display="flex" md={3} xs={6}>
        <Avatar alt="promotion" src={promotion} />
        <Box ml={1}>
          <Typography
            component="div"
            variant="body2"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            {t('pages:point.statusType')}:{' '}
            {t(HistoryStatusOptions.find((el) => el.value === props.historyStatus)?.label as any)}
          </Typography>
          <Typography variant="body2" component="div" color="grey.400">
            {dayjs(props.createdAt).format(FormatDate.DATE_TIME)}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={3} xs={6} sx={{ alignSelf: breakpoints.md ? 'center' : 'flex-start' }}>
        <Typography
          variant="body1"
          component="div"
          textAlign={'left'}
          color={isReceivePoint() ? 'primary' : 'error'}
        >
          {isReceivePoint()
            ? t('pages:users.receivePoint', {
                value: formatCurrency(props.changedValue, 0, 3),
              })
            : t('pages:users.usedPoint', {
                value: formatCurrency(Math.abs(props.changedValue), 0, 3),
              })}
        </Typography>
      </Grid>
      <Grid
        item
        md={4}
        xs={12}
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <Typography variant="body2" component="div" pl={breakpoints.md ? 0 : 6}>
          {t('pages:orders.orderNumber')}:{' '}
          <Typography
            variant="body1"
            component={Link}
            to={generatePath(MyProfileRoutes.PURCHASE_ORDER_DETAIL, {
              id: props.orderId,
            })}
            sx={{
              color: 'primary.main',
              textDecoration: 'none',
            }}
          >
            {props.orderNumber}
          </Typography>
        </Typography>
        {breakpoints.md && (
          <IconButton
            color="primary"
            href={generatePath(MyProfileRoutes.PURCHASE_ORDER_DETAIL, {
              id: props.orderId,
            })}
          >
            <ChevronRightOutlined />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default observer(DetailPoint);
