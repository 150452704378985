import { PropsWithChildren } from 'react';

import { Box, BoxProps } from '@mui/material';

type Props = PropsWithChildren<{
  src: string;
  resizeMode?: 'contain' | 'cover' | string;
  containerSx?: BoxProps['sx'];
  sx?: BoxProps['sx'];
}>;

const BoxImg = ({ children, containerSx, src, resizeMode = 'cover', sx }: Props) => {
  return (
    <Box sx={containerSx}>
      <Box
        width="100%"
        height="100%"
        sx={{
          backgroundImage: `url(${src})`,
          backgroundSize: resizeMode,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          ...sx,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default BoxImg;
