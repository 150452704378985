import { Box, Button, Dialog, Typography } from '@mui/material';
import { useBreakpoints, useDisclosure } from '@vklink/components-shared';
import { VerifiedUserOutlined } from '@mui/icons-material';
import StickyDialogHeader from '../StickyDialogHeader';
import { RadioGroupField, useForm } from '@vklink/components-form';
import { CancelOptions } from 'constant';
import { useI18n } from '@vklink/libs-i18n';

type Props = {
  onClick: () => void;
};

const CancelOrderButton = (props: Props) => {
  const { isOpen, toggle } = useDisclosure();
  const breakpoints = useBreakpoints();
  const { control } = useForm();
  const { t } = useI18n(['common', 'pages']);

  const handleCancelOrder = () => {
    toggle();
    props.onClick();
  };

  return (
    <>
      <Button variant="contained" color="error" onClick={toggle}>
        {t('common:actions.cancelOrder')}
      </Button>

      <Dialog
        open={isOpen}
        onClose={toggle}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: 620,
          },
          '& .MuiPaper-root.MuiDialog-paper': {
            backgroundColor: 'common.white',
          },
        }}
        fullScreen={!breakpoints.md}
      >
        <Box sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
          {breakpoints.md ? (
            <Typography variant="h6" component="div" color="secondary.main" px={2} pt={2}>
              {t('common:actions.cancelOrder')}
            </Typography>
          ) : (
            <StickyDialogHeader
              title={
                <Typography variant="h6" component="div">
                  {t('common:actions.cancelOrder')}
                </Typography>
              }
              onBack={toggle}
            />
          )}
          <Box p={2}>
            <Typography variant="inherit" component="div" pb={2} color="primary.main">
              {t('pages:orders.reasonCancellation')}
            </Typography>
            <Box p={1} border={1} borderColor="primary.main" borderRadius={2}>
              <Box display="flex" alignItems="center">
                <Box width={32}>
                  <VerifiedUserOutlined fontSize="small" color="primary" />
                </Box>
                <Typography variant="body1" component="div" pb={1}>
                  {t('pages:orders.secureCard')}
                </Typography>
              </Box>
              <Typography variant="body2" component="div" pl={4}>
                {t('pages:orders.selectReasonCancel')}
              </Typography>
            </Box>
            <RadioGroupField
              control={control}
              name="reason"
              options={CancelOptions}
              sx={{ py: 2 }}
              t={t}
            />
            <Box display="flex" gap={2} justifyContent="flex-end">
              <Button
                type="reset"
                variant="outlined"
                sx={{
                  px: 4,
                  // color: 'grey.400',
                  // borderColor: 'grey.400',
                  textTransform: 'uppercase',
                }}
                onClick={toggle}
              >
                {t('common:actions.back')}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                onClick={handleCancelOrder}
                sx={{ px: 4, textTransform: 'uppercase' }}
              >
                {t('common:actions.send')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default CancelOrderButton;
