import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
} from '@mui/material';
import { CloseOutlined, CheckOutlined } from '@mui/icons-material';

type Props = Pick<DialogProps, 'open' | 'maxWidth' | 'fullWidth'> & {
  title: string;
  content: string;
  child?: React.ReactNode;
  yesText?: string;
  noText?: string;
  onYesCallback: () => void;
  onNoCallback: () => void;
  disabledYesText?: boolean;
  disabledNoText?: boolean;
};

const ConfirmDialog = (props: Props) => {
  return (
    <Dialog open={props.open} maxWidth={props.maxWidth || 'xs'} fullWidth={props.fullWidth}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent
        sx={{
          '& .MuiTextField-root': {
            mt: 1,
            mb: 2,
          },
        }}
      >
        <Box>{props.child}</Box>
        <DialogContentText>{props.content}</DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          p: 2,
        }}
      >
        <Button
          type="reset"
          variant="outlined"
          sx={{
            color: 'grey.600',
            borderColor: (theme) => `${theme.palette.grey[300]} !important`,
          }}
          onClick={props.onNoCallback}
          disabled={props.disabledNoText}
        >
          {props.noText || (
            <>
              <CloseOutlined sx={{ mr: 1 }} /> No
            </>
          )}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          onClick={props.onYesCallback}
          disabled={props.disabledYesText}
        >
          {props.yesText || (
            <>
              <CheckOutlined sx={{ mr: 1 }} /> Yes
            </>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
