import { StarBorder } from '@mui/icons-material';
import { Rating, Stack, StackProps, Typography } from '@mui/material';

type Props = {
  rating: number | null;
  sx?: StackProps['sx'];

  hideText?: boolean;
};

const ReadOnlyProductRating = (props: Props) => {
  return (
    <Stack direction="row" sx={props.sx} className="read-only-product-rating">
      <Rating
        className="rating-stars"
        emptyIcon={<StarBorder fontSize="inherit" sx={{ color: 'secondary.main' }} />}
        name="read-only"
        value={props.rating}
        readOnly
        sx={{ fontSize: 20 }}
      />
      {!!props.hideText && (
        <Typography variant="body2" component="span" alignSelf={'flex-end'} ml={1} color="gray">
          {props.rating}
        </Typography>
      )}
    </Stack>
  );
};

export default ReadOnlyProductRating;
