import {
  Box,
  Button,
  Dialog,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { useForm, RadioGroupField, TextField, CheckboxField } from '@vklink/components-form';
import { useBreakpoints, useDisclosure } from '@vklink/components-shared';
import { ElectronicInvoiceOptions } from 'constant';
import StickyDialogHeader from './StickyDialogHeader';

const RequestElectronicInvoiceButton = () => {
  const listDisclaimer = [
    '- E-invoice will be issued by S-Trading.',
    "- S-Trading will issue the invoice using this order's delivery information, if no other invoicing information is indicated above.",
    '- In the event that order is paid by cash with an amount more than 20 million VND, S-Trading will not be held responsible for any problems arising from tax declaration.',
    "- Please note that if the tax code is entered incorrectly, the exported invoice will not have the buyer's tax code to ensure the legitimacy of the invoice",
  ];

  const breakpoints = useBreakpoints();
  const { isOpen, toggle } = useDisclosure();
  const { control, handleSubmit } = useForm();

  const onSubmit = (data: any) => {
    console.log(data);
    toggle();
  };

  return (
    <>
      <Button variant="contained" onClick={toggle} disabled>
        Request an Electronic Invoice
      </Button>

      <Dialog
        open={isOpen}
        onClose={toggle}
        sx={{
          '& .MuiPaper-root': {
            maxWidth: 620,
          },
        }}
        fullScreen={!breakpoints.md}
      >
        <Box sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
          {breakpoints.md ? (
            <Typography variant="h6" component="div" color="secondary.main" px={2} pt={2}>
              Electronic Invoice
            </Typography>
          ) : (
            <StickyDialogHeader
              title={
                <Typography variant="h6" component="div">
                  Electronic Invoice
                </Typography>
              }
              onBack={toggle}
            />
          )}
          <Box p={2} maxHeight={700}>
            <Stack component="form" spacing={1}>
              <Typography
                component="div"
                variant="body1"
                p={1}
                sx={{
                  backgroundColor: 'primary.main',
                  color: 'common.white',
                  borderRadius: 1,
                }}
              >
                Please ensure the information is accurate. Once order is placed, e-invoice
                information cannot be changed
              </Typography>
              <RadioGroupField
                control={control}
                name="type"
                options={ElectronicInvoiceOptions}
                row
                label="Type"
              />
              <TextField control={control} name="name" label="Name" />
              <TextField control={control} name="address" label="Address" />
              <Box>
                <TextField control={control} name="email" label="Email" />
                <Typography
                  component="div"
                  variant="body2"
                  p={1}
                  sx={{
                    color: 'grey.500',
                  }}
                >
                  E-Invoice will be sent to the above email address within 7 working days (excluding
                  Saturday - Sunday)
                </Typography>
              </Box>
              <CheckboxField
                control={control}
                name="isDefault"
                label="Set as default personal billing information"
                size="small"
              />
              <Box
                sx={{
                  backgroundColor: 'grey.200',
                  color: 'grey.700',
                  borderRadius: 1,
                  p: 2,
                }}
              >
                <List
                  dense
                  subheader={
                    <Typography component="div" variant="body1">
                      Disclaimer
                    </Typography>
                  }
                >
                  {listDisclaimer.map((item, index) => (
                    <ListItem key={index} disablePadding>
                      <ListItemText primary={item} />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box display="flex" gap={2} py={2} justifyContent="flex-end">
                <Button
                  type="reset"
                  variant="outlined"
                  sx={{
                    px: 4,
                    color: 'grey.400',
                    borderColor: 'grey.400',
                    width: 120,
                  }}
                  onClick={toggle}
                >
                  BACK
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  sx={{
                    width: 120,
                  }}
                  onClick={handleSubmit(onSubmit)}
                >
                  SUBMIT
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

export default RequestElectronicInvoiceButton;
