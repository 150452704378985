import { lazy, useEffect } from 'react';
import { RouteObject, useRoutes } from 'react-router-dom';

import NotFoundPage from 'pages/shared/NotFoundPage';

import {
  AuthenticationRoutes,
  CartRoutes,
  HomeRoutes,
  MyProfileRoutes,
  PaymentRoutes,
  ProductRoutes,
  StaticRoutes,
} from './routers';
import MasterLayout from './MasterLayout';
import MyProfileLayout from 'pages/profile/layout/MyProfileLayout';
import RegisterLayout from 'pages/register/RegisterLayout';
import HomeLayout from 'pages/home/HomeLayout';
import ProductLayout from 'pages/product-detail/ProductLayout';
import CheckoutLayout from 'pages/payment/CheckoutLayout';
import { SigninCallbackPage, SilentCallbackPage, useAuthStore } from '@vklink/libs-auth';
import { AnonymousRoute, ProtectedRoute } from './components';
import { useRootStore } from 'stores';
import { observer } from '@vklink/libs-state';
import { useCacheAfterAuthenticatedActions } from './hook';

const HomePage = lazy(() => import('pages/home/HomePage'));
const ShoppingCenterPage = lazy(() => import('pages/home/ShoppingCenterPage'));
const ContactUsPage = lazy(() => import('pages/home/ContactUsPage'));

const ProductDetailPage = lazy(() => import('pages/product-detail/ProductDetailPage'));

const SearchPage = lazy(() => import('pages/home/SearchPage'));

const OrderCartPage = lazy(() => import('pages/cart/OrderCartPage'));

const EditProfileInformationPage = lazy(
  () => import('pages/profile/pages/edit-profile/EditProfileInformationPage')
);
const ChangeOldValuePage = lazy(
  () => import('pages/profile/pages/edit-profile/ChangeOldValuePage')
);
const AddNewValuePage = lazy(() => import('pages/profile/pages/edit-profile/AddNewValuePage'));
const ConfirmOtpPage = lazy(
  () => import('pages/profile/pages/edit-profile/ConfirmOtpOldValuePage')
);
const ConfirmOtpNewValuePage = lazy(
  () => import('pages/profile/pages/edit-profile/ConfirmOtpNewValuePage')
);
const ChangePasswordPage = lazy(
  () => import('pages/profile/pages/edit-profile/ChangePasswordPage')
);
const AddressManagementPage = lazy(() => import('pages/profile/AddressManagementPage'));
const BankManagementPage = lazy(() => import('pages/profile/BankManagementPage'));
const PurchaseOrderManagementPage = lazy(() => import('pages/profile/PurchaseOrderManagementPage'));
const NotificationPage = lazy(() => import('pages/profile/NotificationPage'));
const OrderStatusPage = lazy(() => import('pages/profile/OrderStatusPage'));
const PointManagementPage = lazy(() => import('pages/profile/PointManagementPage'));

const RegisterPage = lazy(() => import('pages/register/RegisterPage'));
const ConfirmRegistrationPage = lazy(() => import('pages/register/ConfirmRegistrationPage'));
const LoginPage = lazy(() => import('pages/register/LoginPage'));

const CheckoutPage = lazy(() => import('pages/payment/CheckoutPage'));

const FAQsPage = lazy(() => import('pages/static/FAQsPage'));
const PrivacyPolicyPage = lazy(() => import('pages/static/PrivacyPolicyPage'));
const TermsAndConditionsPage = lazy(() => import('pages/static/TermsAndConditionsPage'));
const BusinessAreasPage = lazy(() => import('pages/static/BusinessAreasPage'));
const MissionVisionPage = lazy(() => import('pages/static/MissionVisionPage'));
const AboutUsPage = lazy(() => import('pages/static/AboutUsPage'));

const ROUTE_PATHS = {
  ROOT: '/',
  NOT_FOUND: '*',
};

const routes: RouteObject[] = [
  {
    path: ROUTE_PATHS.ROOT,
    element: <MasterLayout />,
    children: [
      {
        path: ROUTE_PATHS.ROOT,
        element: <HomeLayout />,
        children: [
          {
            index: true,
            element: <HomePage />,
          },
          {
            path: HomeRoutes.SHOPPING_CENTER,
            element: <ShoppingCenterPage />,
          },
          {
            path: HomeRoutes.SEARCH,
            element: <SearchPage />,
          },
          {
            path: HomeRoutes.CONTACT_US,
            element: <ContactUsPage />,
          },
        ],
      },
      {
        path: ProductRoutes.ROOT,
        element: <ProductLayout />,
        children: [
          {
            index: true,
            element: <ProductDetailPage />,
          },
        ],
      },
      {
        path: AuthenticationRoutes.AUTH,
        element: (
          <AnonymousRoute>
            <RegisterLayout />
          </AnonymousRoute>
        ),
        children: [
          {
            path: AuthenticationRoutes.SIGN_UP,
            element: <RegisterPage />,
          },
          {
            path: AuthenticationRoutes.CONFIRM_REGISTRATION,
            element: <ConfirmRegistrationPage />,
          },
          {
            path: AuthenticationRoutes.LOGIN,
            element: <LoginPage />,
          },
        ],
      },
      {
        path: StaticRoutes.FAQ,
        element: <FAQsPage />,
      },
      {
        path: StaticRoutes.PRIVACY_POLICY,
        element: <PrivacyPolicyPage />,
      },
      {
        path: StaticRoutes.TERMS_AND_CONDITIONS,
        element: <TermsAndConditionsPage />,
      },
      {
        path: StaticRoutes.BUSINESS_AREAS,
        element: <BusinessAreasPage />,
      },
      {
        path: HomeRoutes.ABOUT_US,
        element: <AboutUsPage />,
      },
      {
        path: StaticRoutes.MISSION_N_VISION,
        element: <MissionVisionPage />,
      },
      {
        path: MyProfileRoutes.ROOT,
        element: (
          <ProtectedRoute>
            <MyProfileLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            path: MyProfileRoutes.EDIT_PROFILE,
            element: <EditProfileInformationPage />,
          },
          {
            path: MyProfileRoutes.EDIT_OLD_EMAIL,
            element: <ChangeOldValuePage />,
          },
          {
            path: MyProfileRoutes.EDIT_NEW_EMAIL,
            element: <AddNewValuePage />,
          },
          {
            path: MyProfileRoutes.EDIT_OLD_PHONE,
            element: <ChangeOldValuePage />,
          },
          {
            path: MyProfileRoutes.EDIT_NEW_PHONE,
            element: <AddNewValuePage />,
          },
          {
            path: MyProfileRoutes.CONFIRM_OTP_EMAIL,
            element: <ConfirmOtpPage />,
          },
          {
            path: MyProfileRoutes.CONFIRM_OTP_NEW_EMAIL,
            element: <ConfirmOtpNewValuePage />,
          },
          {
            path: MyProfileRoutes.CONFIRM_OTP_PHONE,
            element: <ConfirmOtpPage />,
          },
          {
            path: MyProfileRoutes.CONFIRM_OTP_NEW_PHONE,
            element: <ConfirmOtpNewValuePage />,
          },
          {
            path: MyProfileRoutes.CHANGE_PASSWORD,
            element: <ChangePasswordPage />,
          },
          {
            path: MyProfileRoutes.ADDRESS_MANAGEMENT,
            element: <AddressManagementPage />,
          },
          {
            path: MyProfileRoutes.BANK_MANAGEMENT,
            element: <BankManagementPage />,
          },
          {
            path: MyProfileRoutes.PURCHASE_ORDER,
            element: <PurchaseOrderManagementPage />,
          },
          {
            path: MyProfileRoutes.ORDER_UPDATE,
            element: <NotificationPage />,
          },
          {
            path: MyProfileRoutes.PROMOTION,
            element: <NotificationPage />,
          },
          {
            path: MyProfileRoutes.PURCHASE_ORDER_DETAIL,
            element: <OrderStatusPage />,
          },
          {
            path: MyProfileRoutes.MY_POINTS,
            element: <PointManagementPage />,
          },
        ],
      },
      {
        path: CartRoutes.ORDER_CART,
        element: (
          <ProtectedRoute>
            <OrderCartPage />
          </ProtectedRoute>
        ),
      },
      {
        path: PaymentRoutes.ROOT,
        element: (
          <ProtectedRoute>
            <CheckoutLayout />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <CheckoutPage />,
          },
          {
            path: PaymentRoutes.NOTIFY_PAYMENT,
            element: <CheckoutPage />,
          },
        ],
      },
    ],
  },
  {
    path: '/auth/signin-callback',
    element: <SigninCallbackPage />,
  },
  {
    path: '/auth/silent-callback',
    element: <SilentCallbackPage />,
  },
  { path: ROUTE_PATHS.NOT_FOUND, element: <NotFoundPage /> },
];

const Routes = () => {
  const { commonStore } = useRootStore();
  const authStore = useAuthStore();
  const { runActions } = useCacheAfterAuthenticatedActions();

  useEffect(() => {
    const run = async () => {
      if (authStore.isAuthenticated) {
        commonStore.getUserInformationAsync();
        await commonStore.getCartItemsAsync();

        runActions();
      }
    };

    run();
  }, [authStore.isAuthenticated]);

  return useRoutes(routes);
};

export default observer(Routes);
