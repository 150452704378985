import 'pure-react-carousel/dist/react-carousel.es.css';

import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel';
import { Box, CardMedia, Stack } from '@mui/material';

type CarouselProps = {
  slider: Slide[];
};

type Slide = {
  src?: string;
  video?: string;
};

const CarouselProduct = (props: CarouselProps) => {
  const renderGroupDotTablet = () => (
    <Box
      pt={2}
      sx={{
        '& .carousel__dot': {
          padding: 0,
          height: 74,
          borderColor: 'grey.300',
          borderStyle: 'solid',
          borderRadius: 1,
        },
        '& .carousel__dot--selected': {
          borderColor: 'primary.light',
          borderRadius: 1,
        },
      }}
    >
      <Stack
        sx={{
          whiteSpace: 'nowrap',
          overflowX: 'scroll',
          placeContent: props.slider.length < 5 ? 'center' : 'flex-start',
          p: 2,

          // hide scrollbar
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
        direction="row"
        spacing={1}
      >
        {props.slider.map((slide, index) => (
          <Dot slide={index} key={index}>
            <Box
              component={'img'}
              alt="image"
              src={slide.src}
              sx={{
                width: 70,
                height: 70,
              }}
            />
          </Dot>
        ))}
      </Stack>
    </Box>
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={props.slider.length}
        infinite
        isIntrinsicHeight
      >
        <Slider style={{ maxHeight: 500 }}>
          {props.slider.map((item, index) => (
            <Slide index={index} key={index}>
              <Box textAlign="center">
                {item.video ? (
                  <CardMedia
                    loading="lazy"
                    component="iframe"
                    height={400}
                    width={400}
                    image={item.video}
                    title="Contemplative Reptile"
                    sx={{ p: 4, border: 0 }}
                  />
                ) : (
                  <Box
                    loading="lazy"
                    component={'img'}
                    src={item.src}
                    sx={{
                      maxWidth: 500,
                      maxHeight: 500,
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                )}
              </Box>
            </Slide>
          ))}
        </Slider>
        {renderGroupDotTablet()}
      </CarouselProvider>
    </Box>
  );
};

export default CarouselProduct;
