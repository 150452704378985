import { Badge, Stack, Typography } from '@mui/material';
import { observer } from '@vklink/libs-state';

type Props = {
  right: () => React.ReactNode;
  leftLabel?: string;
  leftValue?: string | React.ReactNode;

  onMouseOver?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
  onRenderSub?: () => React.ReactNode;
};

const ActionBarButton = (props: Props) => {
  return (
    <Stack
      onMouseOver={props.onMouseOver}
      onMouseLeave={props.onMouseLeave}
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        '& .MuiTypography-root': {
          cursor: 'pointer',
        },
      }}
    >
      {props.right()}
      <Stack>
        <Typography
          sx={{
            opacity: 0.7,
            fontSize: (theme) => theme.typography.fontSize - 0.5,
            color: (theme) => theme.palette.secondary.main,
          }}
        >
          {props.leftLabel}
        </Typography>

        {typeof props.leftValue === 'string' ? (
          <Typography
            sx={{
              maxWidth: 200,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              color: (theme) => theme.palette.secondary.main,
            }}
          >
            {props.leftValue}
          </Typography>
        ) : (
          props.leftValue
        )}
      </Stack>

      {!!props.onRenderSub && props.onRenderSub()}
    </Stack>
  );
};

export default observer(ActionBarButton);

export const BadgeIcon = (props: { value: React.ReactNode; icon: React.ReactNode }) => {
  const Icon = props.icon as any;
  return (
    <Badge
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      showZero
      badgeContent={props.value}
      sx={{
        '& .MuiBadge-badge': {
          cursor: 'pointer',
          backgroundColor: (theme) => theme.palette.primary.main,
          color: (theme) => theme.palette.common.white,
        },
      }}
    >
      <Icon sx={{ fontSize: 30, cursor: 'pointer', color: 'secondary.main' }} />
    </Badge>
  );
};
