import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';

import { Avatar, Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';
import { HeadsetMicOutlined, Mail, Place, Phone } from '@mui/icons-material';

import { HomeRoutes } from '../routers';
import { useI18n } from '@vklink/libs-i18n';
import {
  SubCustomerCareList,
  SubCustomerServiceList,
  SubMyAccountList,
  SubQuickLinksList,
  SupportedPaymentMethods,
  Links,
  SocialNetworks,
  STORE_INFO,
  CONTACT_INFO,
} from 'constant';

import logoVKLink from 'assets/white-logo-ecommerce.svg';

const Footer = () => {
  const { t } = useI18n(['components', 'common']);

  return (
    <Box
      sx={{
        backgroundColor: 'grey.700',
        color: (theme) => theme.palette.common.white,
        py: 7,
      }}
    >
      <Container>
        <Grid container spacing={2} rowGap={2}>
          <Grid item xs={12} sm={12} md={4}>
            <AboutStoreBlock />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Box gap={1} display="flex" flexDirection={'column'}>
              <BlockTitle text={t('components:sideBar.aboutUs')} />
              {SubCustomerCareList.map((item, idx) => {
                return <LinkItem key={idx} {...item} />;
              })}
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Box gap={1} display="flex" flexDirection={'column'}>
              <BlockTitle text={t('common:mixins.customerCare')} />
              {SubCustomerServiceList.map((item, idx) => {
                return <LinkItem key={idx} {...item} />;
              })}
            </Box>
          </Grid>

          <Grid item container xs={12} sm={12} md={4} spacing={2} justifyContent="space-between">
            <Grid item xs={12} sm={6} md={12}>
              <Box gap={1} display="flex" flexDirection={'column'}>
                <BlockTitle text={t('components:footer.paymentMethods')} />
                <Stack direction="row" spacing={1}>
                  {SupportedPaymentMethods.map((el, index) => (
                    <Avatar
                      key={index}
                      sx={{
                        borderRadius: 0,
                        width: '100%',
                        maxWidth: 56,
                        '& img': {
                          objectFit: 'contain',
                        },
                      }}
                      alt={el.name}
                      src={el.image}
                    />
                  ))}
                </Stack>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={12}>
              <Box gap={1} display="flex" flexDirection={'column'}>
                <BlockTitle text={t('components:footer.followUs')} />
                <Stack direction="row" spacing={1}>
                  {SocialNetworks.map((el, index) => (
                    <Avatar
                      key={index}
                      sx={{
                        borderRadius: 0,
                        width: '100%',
                        maxWidth: 56,
                        '& img': {
                          objectFit: 'contain',
                        },
                      }}
                      alt={el.name}
                      src={el.image}
                    />
                  ))}
                </Stack>
              </Box>
            </Grid>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={4}>
            <Box gap={1} display="flex" flexDirection={'column'}>
              <BlockTitle text={t('common:user.myAccount')} />
              {SubMyAccountList.map((item, idx) => {
                return <LinkItem key={idx} {...item} />;
              })}
            </Box>
          </Grid> */}

          {/* <Grid item xs={6} sm={6} md={2}>
            <Box gap={1} display="flex" flexDirection={'column'}>
              <BlockTitle text={t('common:mixins.quickLinks')} />
              {SubQuickLinksList.map((item, idx) => {
                return <LinkItem key={idx} {...item} />;
              })}
            </Box>
          </Grid> */}
        </Grid>

        <Divider sx={{ my: 5, backgroundColor: 'common.white' }} />

        <Typography
          component="p"
          sx={{
            color: 'grey.300',
            fontSize: (theme) => theme.typography.fontSize,
            textAlign: 'center',
          }}
        >
          {/* {t('components:footer.copyright')} ©
          <Typography
            component="span"
            sx={{
              color: 'primary.main',
              mx: 0.5,
            }}
          >
            VGS Shop.
          </Typography>
          {t('components:footer.allRightsReserved')} */}
          Copyright © 2024 VGS. All Rights Reserved.
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;

const LinkItem = (props: { title: string; href?: string }) => {
  const { t } = useI18n();
  const title: any = props.title;

  return (
    <NavLink
      style={{
        textDecoration: 'none',
        backgroundColor: 'transparent',
      }}
      to={props.href || HomeRoutes.ROOT}
    >
      <Typography
        sx={{
          color: 'grey.300',
          cursor: 'pointer',
          transition: '0.5s',
          fontSize: (theme) => theme.typography.fontSize,
          ':hover': {
            pl: 1,
            color: 'primary.main',
          },
        }}
      >
        {t(title)}
      </Typography>
    </NavLink>
  );
};

const BlockTitle = (props: { text: string }) => {
  return (
    <Typography
      variant="h6"
      fontWeight="bold"
      gutterBottom
      sx={{ fontSize: (theme) => theme.typography.fontSize + 4 }}
    >
      {props.text}
    </Typography>
  );
};

const LinksBlock = () => {
  if (!Links.length) {
    return <></>;
  }

  return (
    <Typography
      component="p"
      sx={{
        maxWidth: 950,
        mx: 'auto',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        textAlign: 'center',
        rowGap: 1,

        '& a': {
          cursor: 'pointer',
          fontSize: (theme) => theme.typography.fontSize - 0.4,
          color: 'grey.300',
          px: 1,
          position: 'relative',

          ':last-child': {
            ':before': {
              display: 'none',
            },
          },
        },

        '& a:before': {
          content: '"/"',
          position: 'absolute',
          top: 0,
          right: 0,
          opacity: 0.2,
          transform: 'translateX(50%)',
          color: 'grey.300',
        },

        '& a:hover': {
          color: 'warning.main',
        },
      }}
    >
      {Links.map((item, idx) => {
        return (
          <NavLink
            key={idx}
            style={{
              textDecoration: 'none',
              backgroundColor: 'transparent',
            }}
            to={HomeRoutes.ROOT}
          >
            <Typography
              sx={{
                color: 'grey.300',
                cursor: 'pointer',
                transition: '0.5s',
                fontSize: (theme) => theme.typography.fontSize,
                ':hover': {
                  pl: 1,
                  color: 'warning.main',
                },
              }}
            ></Typography>
            {item.title}
          </NavLink>
        );
      })}
    </Typography>
  );
};

const AboutStoreBlock = () => {
  const navigator = useNavigate();
  const { t } = useI18n(['components']);

  return (
    <Box gap={1} display="flex" flexDirection={'column'}>
      <Box
        component="img"
        sx={{
          height: {
            xs: 40,
            md: 50,
          },
          cursor: 'pointer',
          objectFit: 'contain',
          alignSelf: 'flex-start',
          marginBottom: 2,
        }}
        alt="app-logo"
        src={logoVKLink}
        onClick={() => navigator('/')}
      />

      <Stack
        sx={{
          '& .MuiTypography-root': {
            color: 'common.white',
            fontSize: (theme) => theme.typography.fontSize,
          },

          maxWidth: {
            md: '80%',
            lg: '70%',
          },
        }}
        spacing={{
          xs: 1,
          md: 2,
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
          component="a"
          target="_blank"
          href={CONTACT_INFO.phoneNumberHref}
        >
          <Phone
            sx={{
              color: 'common.white',
            }}
          />
          <Typography>{CONTACT_INFO.phoneNumber}</Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={1}
          sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
          component="a"
          target="_blank"
          href={CONTACT_INFO.emailHref}
        >
          <Mail
            sx={{
              color: 'common.white',
            }}
          />
          <Typography>{CONTACT_INFO.email}</Typography>
        </Stack>

        <Stack
          flexWrap="nowrap"
          direction="row"
          spacing={1}
          sx={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
          component="a"
          target="_blank"
          href={STORE_INFO.googleMapUrl}
        >
          <Place
            sx={{
              color: 'common.white',
            }}
          />
          <Typography>{STORE_INFO.address}</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
