import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';

import { NorthOutlined } from '@mui/icons-material';

import { Box, CircularProgress, Fab } from '@mui/material';
import { useBreakpoints } from '@vklink/components-shared';

const FloatingTopButton = () => {
  const breakpoints = useBreakpoints();
  const { pathname } = useLocation();
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const backToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  };

  const renderAbsoluteBox = (child: React.ReactNode) => {
    return (
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {child}
      </Box>
    );
  };

  const onScroll = () => {
    const element = document.body;
    const parent: any = element.parentNode;

    if (!parent) {
      return;
    }

    const percentage = Math.round(
      ((element.scrollTop || parent.scrollTop) / (parent.scrollHeight - parent.clientHeight)) * 100
    );

    setScrollPercentage(Number.isNaN(percentage) ? 0 : percentage);
  };

  useEffect(() => {
    document.addEventListener('scroll', onScroll);

    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      onScroll();
    }, 100);
  }, [pathname]);

  return (
    <Fab
      size={breakpoints.md ? 'medium' : 'small'}
      onClick={backToTop}
      sx={{
        position: 'fixed',
        bottom: {
          xs: 15,
          md: 50,
        },
        right: {
          xs: 15,
          md: 50,
        },
        display: scrollPercentage < 4 ? 'none' : undefined,
        backgroundColor: 'primary.main',
        boxShadow: 0,

        ':hover': {
          backgroundColor: 'primary.main',
        },
      }}
    >
      {/* {renderAbsoluteBox(
        <CircularProgress
          variant="determinate"
          sx={{
            width: '100% !important',
            height: '100% !important',
            color: 'common.white',
          }}
          thickness={4}
          value={100}
        />
      )}
      {renderAbsoluteBox(
        <CircularProgress
          variant="determinate"
          value={scrollPercentage}
          sx={{
            width: '100% !important',
            height: '100% !important',
            color: 'primary.main',
          }}
          thickness={4}
        />
      )} */}

      <NorthOutlined sx={{ fontSize: 20, color: 'common.white' }} />
    </Fab>
  );
};

export default FloatingTopButton;
