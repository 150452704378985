import { useState } from 'react';

import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { useForm, TextField } from '@vklink/components-form';
import { WestOutlined } from '@mui/icons-material';

type AddBankProps = {
  onClose: () => void;
};

const AddBank = (props: AddBankProps) => {
  const { control } = useForm<any>();
  const [step, setStep] = useState(1);

  const handleClose = () => {
    if (step === 3) {
      props.onClose && props.onClose();
      return;
    }

    setStep((prev) => prev + 1);
  };

  return (
    <Box p={4} sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
      {step === 1 && (
        <>
          <Typography variant="h6" component="p" pb={2} sx={{ fontWeight: 'bold' }}>
            Add Bank Account
          </Typography>
          <Stack spacing={2}>
            <TextField control={control} name="name" placeholder="Name" />
            <TextField control={control} name="cmnd" placeholder="Identity card number" />
            <TextField control={control} name="bankName" placeholder="Bank name" />
            <TextField control={control} name="branchName" placeholder="Bank branch name" />
            <TextField control={control} name="accountNumber" placeholder="Account number" />
            <TextField
              control={control}
              name="capitalized"
              placeholder="Full name (capitalized, no accents)"
            />
          </Stack>
        </>
      )}
      {step === 2 && (
        <>
          <Box display="flex" alignItems="center" pb={2} mx={-1} gap={1}>
            <IconButton size="small" onClick={() => setStep((prev) => prev - 1)}>
              <WestOutlined fontSize="small" />
            </IconButton>
            <Typography variant="inherit" component="div">
              Confirm information
            </Typography>
          </Box>
          <Stack spacing={1} color="gray">
            <Box display="flex">
              <Typography variant="inherit" component="div">
                Name Bank
              </Typography>
              <Typography variant="inherit" component="div">
                Techcombank - NHTMCP - Ky Thuong VN
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="inherit" component="div">
                Bank branch name
              </Typography>
              <Typography variant="inherit" component="div">
                Pham Ngoc Thach Ho Chi Minh
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="inherit" component="div">
                Account number
              </Typography>
              <Typography variant="inherit" component="div">
                19032 4824 86011
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="inherit" component="div">
                Full name
              </Typography>
              <Typography variant="inherit" component="div">
                TRANG HONG TUAN
              </Typography>
            </Box>
          </Stack>
        </>
      )}
      {step === 3 && (
        <>
          <Box display="flex" alignItems="center" pb={2} mx={-1} gap={1}>
            <IconButton size="small" onClick={() => setStep((prev) => prev - 1)}>
              <WestOutlined fontSize="small" />
            </IconButton>
            <Typography variant="inherit" component="div">
              Enter Verification Code
            </Typography>
          </Box>
          <TextField
            control={control}
            name="verificationCode"
            placeholder="Please enter the confirmation code that has just been sent..."
          />
        </>
      )}

      <Box display="flex" gap={2} justifyContent="right" mt={2}>
        <Button
          type="reset"
          variant="outlined"
          sx={{
            px: 4,
            color: 'grey.600',
            borderColor: 'grey.400',
          }}
          onClick={() => props.onClose && props.onClose()}
        >
          Back
        </Button>
        <Button type="submit" variant="contained" color="primary" onClick={handleClose}>
          {step === 3 ? 'Complete' : 'Next'}
        </Button>
      </Box>
    </Box>
  );
};

export default AddBank;
