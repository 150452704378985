import { Box, Button, Stack, Typography, Grid } from '@mui/material';
import {
  SelectField,
  TextField,
  useForm,
  RadioGroupField,
  CheckboxField,
} from '@vklink/components-form';
import { observer } from '@vklink/libs-state';
import { TypeAddressOptions } from 'constant';
import { yup, yupResolver } from '@vklink/components-form';
import {
  CreateUserShippingAddress,
  DefaultUserShippingAddressValue,
} from 'pages/profile/store/models';
import { useAddressCatalogStore } from '../stores';
import { useEffect } from 'react';
import { isVietnamesePhoneNumber } from '../utils';
import { useI18n } from '@vklink/libs-i18n';

type AddNewAddressProps = {
  onClose?: () => void;
  onComplete: (data: CreateUserShippingAddress) => void;
  addressId?: string;
};

const AddNewAddress = (props: AddNewAddressProps) => {
  const { t } = useI18n(['common', 'pages']);
  const validationSchema: yup.SchemaOf<CreateUserShippingAddress> = yup
    .object()
    .shape({
      contactName: yup.string().required().max(50).label(t('common:address.receiverName')),
      contactPhone: yup
        .string()
        .matches(isVietnamesePhoneNumber)
        .required()
        .max(20)
        .label(t('pages:users.phoneNumber')),
      fullAddress: yup.string().required().max(100).label(t('common:address.address')),
      provinceId: yup.string().required().label(t('common:address.province')),
      districtId: yup.string().required().label(t('common:address.district')),
      wardId: yup.string().required().label(t('common:address.ward')),
    })
    .defined();

  const { control, handleSubmit, watch, setValue, reset } = useForm<CreateUserShippingAddress>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: DefaultUserShippingAddressValue,
  });
  const store = useAddressCatalogStore();
  const provinceId = watch('provinceId');
  const districtId = watch('districtId');

  useEffect(() => {
    let active = true;
    (async () => {
      await store.getProvincesAsync();
      loadAddressById();

      if (!active) return;
    })();

    return () => {
      active = false;
    };
  }, []);

  const loadAddressById = async () => {
    if (props.addressId) {
      await store.getShippingAddressDetailAsync(props.addressId);
      await store.getDistrictsAsync(store.shippingAddressDetail?.provinceId);
      await store.getWardsAsync(store.shippingAddressDetail?.districtId);
      reset(store.shippingAddressDetail);
    }
  };

  const handleChangeProvince = (provinceId: unknown) => {
    store.getDistrictsAsync(provinceId as string);
    setValue('districtId', '');
    setValue('wardId', '');
  };

  const handleChangeDistrict = (districtId: unknown) => {
    store.getWardsAsync(districtId as string);
    setValue('wardId', '');
  };

  const handleClose = () => {
    props.onClose && props.onClose();
  };

  const onSubmit = (data: CreateUserShippingAddress) => {
    const address = {
      ...data,
      provinceCode: store.provinces.find((x) => x.id === data.provinceId)?.code,
      provinceName: store.provinces.find((x) => x.id === data.provinceId)?.name ?? '',
      districtCode: store.districts.find((x) => x.id === data.districtId)?.code,
      districtName: store.districts.find((x) => x.id === data.districtId)?.name ?? '',
      wardCode: store.wards.find((x) => x.id === data.wardId)?.code,
      wardName: store.wards.find((x) => x.id === data.wardId)?.name ?? '',
    };

    props.onComplete(address);
  };

  return (
    <Box p={4} sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
      <Typography variant="h6" component="p" pb={2} sx={{ fontWeight: 'bold' }}>
        {props.addressId ? t('common:actions.edit') : t('common:actions.add')}{' '}
        {t('common:address.deliveryAddress').toLowerCase()}
      </Typography>

      <Stack
        spacing={2}
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <TextField
              control={control}
              name="contactName"
              placeholder={t('common:address.receiverName')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              control={control}
              name="contactPhone"
              placeholder={t('pages:users.phoneNumber')}
            />
          </Grid>
        </Grid>
        <SelectField
          control={control}
          name="provinceId"
          options={store.getListProvinces}
          placeholder={t('common:address.province')}
          onChange={handleChangeProvince}
        />
        <SelectField
          control={control}
          name="districtId"
          options={store.getListDistricts}
          placeholder={t('common:address.district')}
          disabled={!provinceId}
          onChange={handleChangeDistrict}
        />
        <SelectField
          control={control}
          name="wardId"
          options={store.getListWards}
          placeholder={t('common:address.ward')}
          disabled={!districtId}
        />
        <TextField control={control} name="fullAddress" placeholder={t('common:address.address')} />
        <RadioGroupField
          row
          control={control}
          name="address1"
          label={t('common:address.addressType')}
          options={TypeAddressOptions}
          t={t}
        />
        <CheckboxField
          control={control}
          name="isDefault"
          label={t('common:address.setAsDefault')}
        />
        <Box display="flex" gap={2} justifyContent="flex-end">
          <Button
            type="reset"
            variant="outlined"
            sx={{
              px: 4,
              color: 'grey.600',
              borderColor: 'grey.300',

              ':hover': {
                borderColor: 'grey.300',
              },
            }}
            onClick={handleClose}
          >
            {t('common:actions.back')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: 'primary.main',

              ':hover': {
                backgroundColor: 'primary.main',
              },
            }}
          >
            {t('common:actions.complete')}
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default observer(AddNewAddress);
