import { createTheme } from '@mui/material/styles';
import { defaultTheme } from '@vklink/components-shared';

const primaryColor = '#3AC5C9';

const theme = createTheme(defaultTheme, {
  palette: {
    mode: 'light',
    primary: {
      main: '#3AC5C9',
      light: '#3AC5C9',
    },
    secondary: {
      main: '#797687',
    },
    success: {
      main: '#0AB39C',
      light: '#5aab19',
    },
    info: {
      main: '#0288D1',
    },
    warning: {
      main: '#fcbe00',
      dark: '#f9a825',
    },
    error: {
      main: '#D32F2F',
      dark: '#CC1414',
    },
    grey: {
      [700]: '#4A4A4A',
      [800]: '#2F3037',
      [900]: '#222222',
    },
    divider: 'rgba(255, 255, 255, 0.1)',
  },
  mixins: {
    toolbar: {
      minHeight: 64,
    },
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: primaryColor,

          '&.Mui-checked': {
            color: primaryColor,
          },

          '&.MuiCheckbox-indeterminate': {
            color: primaryColor,
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: primaryColor,

          '&.Mui-checked': {
            color: primaryColor,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.MuiInputBase-root.Mui-focused > fieldset': {
            borderColor: primaryColor,
            borderWidth: 1,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          marginBottom: -0.5 * 8,
        },
      },
    },
    MuiStack: {
      styleOverrides: {
        root: {
          '&.otp-input': {
            '& .MuiInput-root': {
              ':hover': {
                ':not(.Mui-disabled)': {
                  ':before': {
                    borderBottomColor: primaryColor,
                  },
                },
              },
              ':after': {
                borderBottomColor: primaryColor,
              },
            },
          },
        },
      },
    },
  },
});

export default theme;
