import { Typography, TypographyProps } from '@mui/material';

type Props = {
  text: string;
  onClick?: () => void;
  sx?: TypographyProps['sx'];
};

const UnderlineText = (props: Props) => {
  return (
    <Typography
      onClick={props.onClick}
      component="p"
      sx={{
        color: 'grey.600',
        position: 'relative',
        fontWeight: 'bold',

        ...props.sx,

        ':after': {
          position: 'absolute',
          content: '""',
          width: 100,
          height: 2.5,
          background: (theme) => theme.palette.primary.main,
          bottom: 0,
          left: 0,

          ...(props.sx ? (props.sx as any)[':after'] : {}),
        },
      }}
    >
      {props.text}
    </Typography>
  );
};

export default UnderlineText;
