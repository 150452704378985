import { Container, Box, Typography, Grid, IconButton, Stack, Button } from '@mui/material';
import {
  Facebook,
  Twitter,
  YouTube,
  LinkedIn,
  RssFeed,
  SportsBasketballOutlined,
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import { TextField, useForm, yup, yupResolver } from '@vklink/components-form';

import AndroidAppImg from 'assets/app_android.png';
import IosAppImg from 'assets/app_ios.png';
import { useRootStore } from 'stores';
import { useToast } from '@vklink/components-toast-message';
import { useBreakpoints } from '@vklink/components-shared';
import { useI18n } from '@vklink/libs-i18n';

const SocialNetworkList = [
  { icon: Facebook, name: 'facebook', color: '#3b5998' },
  { icon: Twitter, name: 'twitter', color: '#1da1f2' },
  { icon: YouTube, name: 'youtube', color: '#cd201f' },
  { icon: LinkedIn, name: 'linkedIn', color: '#0077b5' },
  { icon: RssFeed, name: 'rssFeed', color: '#f26522' },
  { icon: SportsBasketballOutlined, name: 'dribbble', color: '#ea4c89' },
];

const CtaArea = () => {
  const breakpoints = useBreakpoints();
  const { t } = useI18n(['components']);

  return (
    <Box
      sx={{
        backgroundColor: 'grey.800',
        color: (theme) => theme.palette.common.white,
        py: 6,
      }}
    >
      <Container>
        <Grid
          container
          rowGap={2}
          sx={{
            '& .MuiGrid-item': {
              pr: {
                sm: 3,
                md: 6,
              },
            },
          }}
        >
          <Grid item xs={12} sm={6} md={4}>
            <BlockTitle text={t('components:footer.followUs')} />
            <BlockDescription text={t('components:footer.followUsContent')} />

            <Stack
              direction="row"
              rowGap={1}
              flexWrap="wrap"
              sx={{
                '& .MuiButtonBase-root': {
                  ':not(:last-child)': {
                    mr: 1,
                  },
                },
              }}
            >
              {SocialNetworkList.map((item, idx) => {
                const Icon = item.icon;

                return (
                  <IconButton
                    key={idx}
                    sx={{
                      borderRadius: 0.5,
                      backgroundColor: item.color,
                      color: 'common.white',
                      transition: '0.5s',
                      width: 45,
                      height: 45,

                      ':hover': {
                        backgroundColor: 'primary.main',
                        color: 'grey.900',
                      },
                    }}
                  >
                    <Icon />
                  </IconButton>
                );
              })}
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <BlockTitle text={t('components:footer.signUpNewsletter')} />
            <BlockDescription text={t('components:footer.signUpNewsletterContent')} />

            <EmailInput />
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: breakpoints.md ? 'block' : 'none',
            }}
          >
            <BlockTitle text={t('components:footer.downloadApp')} />
            <BlockDescription text={t('components:footer.downloadAppContent')} />

            <Stack
              direction="row"
              flexWrap="wrap"
              rowGap={1}
              sx={{
                '& img': {
                  height: 45,
                  objectFit: 'contain',

                  ':first-child': {
                    mr: 2,
                  },
                },
              }}
            >
              <img src={IosAppImg} alt="ios-app" />
              <img src={AndroidAppImg} alt="android-app" />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CtaArea;

const BlockTitle = (props: { text: string }) => {
  return (
    <Typography
      variant="h6"
      fontWeight="bold"
      gutterBottom
      sx={{ fontSize: (theme) => theme.typography.fontSize + 4 }}
    >
      {props.text}
    </Typography>
  );
};

const BlockDescription = (props: { text: string }) => {
  return (
    <Typography
      component="p"
      gutterBottom
      sx={{
        fontSize: (theme) => theme.typography.fontSize - 0.5,
        color: 'grey.300',
        minHeight: 60,
      }}
    >
      {props.text}
    </Typography>
  );
};

const EmailInput = () => {
  const validationSchema: yup.SchemaOf<{ email: string }> = yup
    .object()
    .shape({
      email: yup.string().email().required().max(50).label('Email'),
    })
    .defined();

  const theme = useTheme();
  const { commonStore } = useRootStore();
  const toast = useToast();
  const { t } = useI18n(['common']);
  const { control, handleSubmit, setValue } = useForm<{ email: string }>({
    mode: 'all',
    resolver: yupResolver(validationSchema),
    defaultValues: { email: '' },
  });

  const onSubmit = (data: any) => {
    commonStore.postEmailNotificationAsync(data.email, {
      success: () => {
        setValue('email', '');
        toast(
          'Success',
          'You have successfully subscribed to our newsletter.',
          'success',
          'filled'
        );
      },
      error: (err) => {
        toast('Error', err.response.data.title, 'error', 'filled');
      },
    });
  };

  return (
    <Stack component={'form'} onSubmit={handleSubmit(onSubmit)}>
      <TextField
        control={control}
        name="email"
        sx={{
          '& .MuiOutlinedInput-root': {
            flex: 1,
            pr: 0,
            backgroundColor: (theme) => theme.palette.common.white,
            '& fieldset': {
              borderColor: 'transparent !important',
            },

            // ':focus': {
            //   '& fieldset': {
            //     borderColor: 'transparent',
            //   },
            // },
          },
        }}
        autoComplete="off"
        placeholder={t('common:placeholder.enterEmail')}
        endAdornment={
          <Button
            type="submit"
            variant="contained"
            sx={{
              borderRadius: 0,
              borderTopRightRadius: 'inherit',
              borderBottomRightRadius: 'inherit',
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.common.black,
              fontWeight: 'bold',
              px: 4,
              fontSize: (theme) => theme.typography.fontSize - 1,
              height: 45,
              whiteSpace: 'nowrap',
              ':hover': {
                backgroundColor: 'primary.main',
                color: 'common.white',
              },
            }}
          >
            {t('common:actions.subscribe').toUpperCase()}
          </Button>
        }
      />
    </Stack>
  );
};
